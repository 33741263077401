import {
  CButton,
  CCol,
  CInput,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CTooltip,
} from "@coreui/react";
import { ArrowForward, Delete, Satellite } from "@material-ui/icons";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { MapApi } from "src/Config/API";

export function CustomeCouplerShow({
  data,
  viewas,
  map,
  mapDataFinal,
  getMasteData,
  MapKey,
  mapRef,
  DeleteDataByID,
  handleImageView,
  setCouplorIDForSaveLossData,
  sethandleLossModal,
  PostPolilineMax,
  PostPolilineMin,
  PostPolilineMaxContinue,
  PostPolilineMinContinue,
}) {
  const [InputData, setInputData] = useState("");
  const [InputImage, setInputImage] = useState(null);

  useEffect(() => {
    setInputData(data.name);
  }, [data]);
  function EditCouplrById(id, InputValue, InputImage) {
    const form = new FormData();
    form.append("name", InputValue);
    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }

    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "/" +
            lat +
            "," +
            lang
          );


        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  function EditCouplrRotationIconById(id, InputValue) {
    var data = {
      icon_type: InputValue,
    };
    MapApi.UpdateMasterDataById(id, data, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const popupElRef = useRef(null);
  const hideElement = () => {

  };
  return (
    <>
      <Marker
        position={[parseFloat(data.latlang.lat), parseFloat(data.latlang.lang)]}
        icon={
          data.icon_type === "row"
            ? L.icon({
              iconUrl:
                data.loss !== null
                ? MapApi.getCouplerIcon(data.loss.low_name, data.loss.high_name, "row")
                : MapApi.getCouplerIcon(0, 0, "row"),
              iconRetinaUrl:
                data.loss !== null
                ? MapApi.getCouplerIcon(data.loss.low_name, data.loss.high_name, "row")
                : MapApi.getCouplerIcon(0, 0, "row"),

              iconAnchor: [35, 35],
              popupAnchor: [10, -44],
              iconSize: [70, 40],
            })
            : L.icon({
              iconUrl:
                data.loss !== null
                ? MapApi.getCouplerIcon(data.loss.low_name, data.loss.high_name, "row")
                : MapApi.getCouplerIcon(0, 0, "row"),
              iconRetinaUrl:
                data.loss !== null
                ? MapApi.getCouplerIcon(data.loss.low_name, data.loss.high_name, "row")
                : MapApi.getCouplerIcon(0, 0, "row"),
              iconAnchor: [35, 35],
              popupAnchor: [10, -44],
              iconSize: [40, 70],

            })
        }
      >
        <>
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{InputData}</center>
              <img
                src={data.images}
                width="200"
                height={"200"}
              />
            </Tooltip>
          ) : (
            <Tooltip sticky>
              <center>{InputData}</center>
            </Tooltip>
          )}
        </>
        <Popup minWidth={300} closeButton={false} ref={popupElRef}>
          <center>
            <h5 >Coupler</h5>
            {data.loss !== null ? (

              <>
                <span style={{ marginBottom: 10 }}> {data.loss_type ?? ""}</span>
              </>
            ) : (
              <div></div>
            )}
          </center>
          <CTabs activeTab="loss">
            <CTabContent>
              <CTabPane data-tab="loss">

                <table class="table table-bordered table-sm">

                  <tbody>
                    <tr>
                      <td> <CButton
                        onClick={() => {
                          setCouplorIDForSaveLossData();
                          // setSelectedLossId();
                          sethandleLossModal();
                        }}
                        color="info"
                        variant="outline"
                        block
                      >
                        Select Couplor <ArrowForward />
                      </CButton></td>
                      <td> <CButton
                        variant="outline"
                        color="primary"
                        block
                        onClick={() => {
                          getMyLocation({
                            lat: parseFloat(mapDataFinal.latlang.lat),
                            lang: parseFloat(mapDataFinal.latlang.lang),
                          });
                          setLoadingModalShowForDirectionOpenMap(true);
                        }}
                      >
                        {LoadingModalShowForDirectionOpenMap
                          ? "Wait Loading..."
                          : "Get Location"}{" "}
                        {/* <ArrowForward /> */}
                      </CButton>
                        <br />
                        {getlatforcurruntLocation !== "" ? (
                          <center>
                            {" "}
                            <a
                              href={
                                "https://www.google.com/maps/dir/" +
                                getlatforcurruntLocation +
                                "," +
                                getlangforcurruntLocation +
                                "/" +
                                parseFloat(data.latlang.lat) +
                                "," +
                                parseFloat(data.latlang.lang)
                              }
                              target="_BLANK"
                            >
                              Get Directon <ArrowForward />
                            </a>
                          </center>
                        ) : (
                          ""
                        )}</td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-bordered table-sm">

                  <tbody>
                    <tr>
                      <td>Type
                      </td>
                      <td> <center>
                        {" "}
                        {data.loss !== null ? (
                          data.loss.high_name
                        ) : (
                          <div>Max</div>
                        )}{" "}
                      </center>
                      </td>
                      <td> <center>
                        {" "}
                        {data.loss !== null ? (
                          data.loss.low_name
                        ) : (
                          <div>Min</div>
                        )}{" "}
                      </center>
                      </td>
                    </tr>
                    <tr>
                      <td>  {MapKey === "gpon_olt" || MapKey === "epon_olt"
                        ? ""
                        : "CATV"}
                      </td>
                      <td>  <center>OP Power</center>
                      </td>
                      <td>  <center>OP Power</center>
                      </td>
                    </tr>
                    {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                      <></>
                    ) : (
                      <tr>
                        <td>  <div
                          style={{
                            marginTop: 6,
                            fontWeight: "bold",
                          }}
                        >
                          <center>1550NM</center>
                        </div>
                        </td>
                        <td> <div class="input-box-DB">
                          <CInput
                            style={{ width: "100%" }}
                            // mask="99 . 99"
                            // readOnly
                            // value={"111.111"}
                            value={data.max !== null ? data.max.loss_1550NM : 0}
                          />
                          <span class="prefix">DB</span>
                        </div>
                        </td>
                        <td><div class="input-box-DB">
                          <CInput
                            style={{ width: "100%" }}
                            // readOnly
                            value={data.min !== null ? data.min.loss_1550NM : 0}
                          />
                          <span class="prefix">DB</span>
                        </div>
                        </td>
                      </tr>)}
                    INTERNET
                    {mapDataFinal.port.wdm_status === 1 ? (
                      <tr>

                        <td> {mapDataFinal.port.wdm_status === 1 ? (
                          <>
                            <div
                              style={{
                                marginTop: 6,
                                fontWeight: "bold",
                              }}
                            >
                              <center> 1490NM</center>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}</td>
                        <td>  {mapDataFinal.port.wdm_status === 1 ? (
                          <>
                            <div class="input-box-DB">
                              <CInput
                                style={{ width: "100%" }}
                                // mask="99 . 99"
                                // readOnly
                                value={
                                  data.max !== null ? data.max.loss_1490NM : 0
                                }
                              />
                              <span class="prefix">DB</span>
                            </div>
                          </>
                        ) : (
                          <></>)}</td>
                        <td> {mapDataFinal.port.wdm_status === 1 ? (
                          <>
                            <div class="input-box-DB">
                              <CInput
                                style={{ width: "100%" }}
                                // readOnly
                                value={
                                  data.min !== null ? data.min.loss_1490NM : 0
                                }
                              />
                              <span class="prefix">DB</span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}</td>
                      </tr>

                    ) : <></>}
                    <tr>

                      <td>Line</td>
                      <td>  {data.max !== null ? (
                        <>
                          {data.max.status === "Disable" ? (
                            <CButton color="primary" block disabled
                              style={{ padding: 0 }}
                            >
                              {/* <img src={drawpoliicon} height="20" /> */}
                              Add FiberLine
                              {/* <ShowChartRounded /> */}
                            </CButton>
                          ) : data.max.polyline_id !== null ? (
                            <CButton
                              color="primary"
                              block
                              style={{ padding: 0 }}
                              onClick={() => {
                                PostPolilineMaxContinue();
                                // closePopups();
                              }}
                            >
                              FiberLine Continue
                              {/* <img src={drawpoliicon} height="20" /> */}
                            </CButton>
                          ) : (
                            <CButton
                              color="primary"
                              block
                              style={{ padding: 0 }}
                              onClick={() => {
                                PostPolilineMax();
                                // closePopups();
                              }}
                            >
                              Add FiberLine
                              {/* <img src={drawpoliicon} height="20" /> */}
                            </CButton>
                          )}
                        </>
                      ) : (
                        <div></div>
                      )}</td>
                      <td>   {data.min !== null ? (
                        <>
                          {data.min.status === "Disable" ? (
                            <CButton color="primary" block disabled style={{ padding: 0 }}>
                              {/* <img src={drawpoliicon} height="20" /> */}
                              <span></span> Add FiberLine
                              {/* <ShowChartRounded /> */}
                            </CButton>
                          ) : data.min.polyline_id !== null ? (
                            <CButton
                              color="primary"
                              block
                              style={{ padding: 0 }}
                              onClick={() => {
                                PostPolilineMinContinue();
                                // closePopups();
                              }}
                            >
                              FiberLine Continue
                              {/* <img src={drawpoliicon} height="20" /> */}
                            </CButton>
                          ) : (
                            <CButton
                              color="primary"
                              style={{ padding: 0 }}
                              block
                              onClick={() => {
                                PostPolilineMin();
                                hideElement();
                              }}
                            >
                              {/* <img src={drawpoliicon} height="20" /> */}
                              Add FiberLine
                              {/* <ShowChartRounded /> */}
                            </CButton>
                          )}
                        </>
                      ) : (
                        <div></div>
                      )}</td>
                    </tr>
                  </tbody>
                </table>
              </CTabPane>

              <CTabPane data-tab="other_info">
                <br />


                <table class="table table-bordered table-sm">

                  <tbody>
                    <tr>
                      <td>
                        <CInput
                          onChange={(v) => setInputData(v.target.value)}
                          value={InputData}
                        ></CInput>
                      </td>

                    </tr>
                    <tr>

                      <td>
                        <p
                          style={{
                            color: "black",
                            margin: 0,
                            fontWeight: "bold",
                          }}
                        >
                          Upload Image{" "}
                          {data.images !== null ? (
                            // <a
                            //   href={data.images}
                            //   target="blank"
                            // >
                            <Satellite onClick={() => handleImageView()} style={{ margin: 0 }} />
                          ) : (
                            // </a>
                            <></>
                          )}
                        </p>
                      </td>
                      <td>

                        <CInput
                          type="file"
                          placeholder=""
                          onChange={(v) => setInputImage(v.target.files[0])}
                        // value={InputImage}
                        ></CInput>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-bordered table-sm">

                  <tbody>
                    <tr>
                      <td>
                        <CButton
                          onClick={() =>
                            EditCouplrById(data.id, InputData, InputImage)
                          }
                          color="primary"
                          block
                        // variant="outline"
                        >
                          {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                          Save{" "}
                        </CButton>
                      </td>
                      <td>
                        <CButton
                          onClick={() => DeleteDataByID()}
                          color="secondary"
                          block
                          variant="outline"
                        >
                          <Delete color="#ff0000" style={{ color: "red" }} />
                        </CButton>
                      </td>
                      <td>
                        <CTooltip content={"Landscape Coupler"} placement="top">
                          <CButton
                            onClick={() =>
                              EditCouplrRotationIconById(data.id, "row")
                            }
                            color="info"
                            block
                            variant="outline"
                          >
                            Landscape
                            {/* <Autorenew color="#ff0000" style={{ color: "blue" }} /> */}
                          </CButton>
                        </CTooltip>
                      </td>

                      <td>
                        <CTooltip content={"Portrait Coupler"} placement="top">
                          <CButton
                            onClick={() =>
                              EditCouplrRotationIconById(data.id, "Column")
                            }
                            color="info"
                            block
                            variant="outline"
                          >
                            Portrait
                            {/* <Sync color="#ff0000" style={{ color: "blue" }} /> */}
                          </CButton>
                        </CTooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </CTabPane>
            </CTabContent>
            <CNav variant="tabs" justified="center">
              <CNavItem>
                <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                  Loss{" "}
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  data-tab="other_info"
                  style={{ fontFamily: "sans-serif" }}
                >
                  Other Info
                </CNavLink>
              </CNavItem>
            </CNav>
          </CTabs>
          <CRow style={{ marginTop: 10 }}>
            <CCol>

            </CCol>
          </CRow>
        </Popup>
      </Marker>
    </>
  );
}
