import React, { useEffect, useState } from "react";
import { LossAPI, MapApi } from "src/Config/API";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CModalHeader,
  CModal,
  CForm,
  CRow,
  CContainer,
} from "@coreui/react";
import InputMask from "react-input-mask";
import optwoc from "./../../../../assets/losses/optwoc.jpg";
import { ArrowBack } from "@material-ui/icons";
function LossChart({
  show,
  onClose,
  CouplorIDForSaveLossData,
  onupdateData,
  MapKey,
}) {
  const [SelectedLossId, setSelectedLossId] = useState(0);
  const [CLossWithData, setCLossWithData] = useState([]);
  const [CLossWithoutData, setCLossWithoutData] = useState([]);
  const [checkTypeOfLoss, setcheckTypeOfLoss] = useState("");
  useEffect(() => {
    getCoulporLossWith(2);
    getCoulporLossWithout(3);
  }, []);

  function getCoulporLossWith(catid) {
    LossAPI.getLossData(catid)
      .then(function (response) {
        setCLossWithData(response.data.loss);

        //console.log(response.data);
      })
      .catch(function (err) {
        //console.log("err");
      });
  }
  function getCoulporLossWithout(catid) {
    LossAPI.getLossData(catid)
      .then(function (response) {
        setCLossWithoutData(response.data.loss);

        //console.log(response.data);
      })
      .catch(function (err) {
        //console.log("err");
      });
  }
  const handleUpdateLossData = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("loss_id", SelectedLossId);
    MapApi.UpdateMasterDataById(
      CouplorIDForSaveLossData,
      {
        loss_id: SelectedLossId,
      },
      MapKey
    )
      .then((rsponse) => {
        //console.log(rsponse.data);
        onClose();
        onupdateData();
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  return (
    <CModal
      show={show}
      onClose={onClose}
      className="modal-centered"
      // size="lg"
      closeOnBackdrop={false}
      fade
    >
      <CModalHeader closeButton>
        <h3>
          <center>Select Loss From Charts</center>
        </h3>
      </CModalHeader>
      {checkTypeOfLoss === "" ? (
        <>
          <CCard style={{ padding: 20 }}>
            <br />
            <br />
            <CRow>
              <CCol>
                <CButton
                  onClick={() => setcheckTypeOfLoss("losswith")}
                  color="info"
                  block
                >
                  Optical Coupler Loss (With Connector)
                </CButton>
              </CCol>
              <CCol>
                <CButton
                  onClick={() => setcheckTypeOfLoss("losswithout")}
                  color="info"
                  block
                >
                  Optical Coupler Loss (Without Connector)
                </CButton>
              </CCol>
            </CRow>
            <br />
          </CCard>
        </>
      ) : (
        <></>
      )}

      <CRow>
        {checkTypeOfLoss === "losswith" ? (
          <CCol>
            <div>
              <ArrowBack
                style={{ margin: 20, cursor: "pointer" }}
                onClick={() => setcheckTypeOfLoss("")}
              />
              <center>
                <h4 style={{ display: "inline-block" }}>
                  Optical Coupler Loss (With Connector)&nbsp;&nbsp;&nbsp;
                  <img src={optwoc}
                    width="10%"
                    className="imgstyle"
                  />
                </h4>
              </center>
              <CForm onSubmit={handleUpdateLossData}>
                <CContainer>
                  <CCard>
                    <CCardBody>
                      <table class="table table-borderless table-sm">
                        {CLossWithData?.map((Loss, index) => (
                          <tr>
                            <td>
                              {Loss.high_name} &#10006; {Loss.low_name}
                            </td>
                            <td>&#10140;</td>
                            <td>
                              <InputMask
                                mask="99.99"
                                className="inputsm"
                                type="text"
                                name="high_value"
                                value={Loss.high_value}
                              ></InputMask>{" "}
                              DB
                            </td>
                            <td>
                              <InputMask
                                mask="99.99"
                                className="inputsm"
                                type="text"
                                name="low_value"
                                value={Loss.low_value}
                              ></InputMask>{" "}
                              DB
                            </td>
                            <td>
                              <input
                                type="radio"
                                name="selection"
                                value={Loss.id}
                                // checked={Loss.id === SelectedLossId ? true :false}
                                onChange={(v) => {
                                  //console.log(v.target.value);
                                  setSelectedLossId(v.target.value);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </table>
                      <center>
                        <CButton
                          color="info"
                          style={{ display: "inline-block" }}
                          type="submit"
                        >
                          save
                        </CButton>
                        &nbsp;&nbsp;
                        <CButton color="info" onClick={() => onClose()}>
                          Close
                        </CButton>
                      </center>
                    </CCardBody>
                  </CCard>
                </CContainer>
              </CForm>
            </div>
          </CCol>
        ) : checkTypeOfLoss === "losswithout" ? (
          <CCol>
            <ArrowBack
              style={{ margin: 20, cursor: "pointer" }}
              onClick={() => setcheckTypeOfLoss("")}
            />
            <div>
              <center>
                <h4 style={{ display: "inline-block" }}>
                  Optical Coupler Loss (Without Connector)&nbsp;&nbsp;&nbsp;
                  <img src={optwoc}
                    width="10%"
                    className="imgstyle"
                  />
                </h4>
              </center>
              <CForm onSubmit={handleUpdateLossData}>
                <CContainer>
                  <CCard>
                    <CCardBody>
                      <table class="table table-borderless table-sm">
                        {CLossWithoutData?.map((Loss, index) => (
                          <tr>
                            <td>
                              {Loss.high_name} &#10006; {Loss.low_name}
                            </td>
                            <td>&#10140;</td>
                            <td>
                              <InputMask
                                mask="99.99"
                                className="inputsm"
                                type="text"
                                name="high_value"
                                value={Loss.high_value}
                              ></InputMask>{" "}
                              DB
                            </td>
                            <td>
                              <InputMask
                                mask="99.99"
                                className="inputsm"
                                type="text"
                                name="low_value"
                                value={Loss.low_value}
                              ></InputMask>{" "}
                              DB
                            </td>
                            <td>
                              <input
                                type="radio"
                                name="selection"
                                value={Loss.id}
                                // checked={Loss.id === SelectedLossId ? true :false}
                                onChange={(v) => {
                                  // //console.log(v.target.value);
                                  setSelectedLossId(v.target.value);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </table>
                      <center>
                        <CButton
                          color="info"
                          style={{ display: "inline-block" }}
                          type="submit"
                        >
                          save
                        </CButton>
                        &nbsp;&nbsp;
                        <CButton color="info" onClick={() => onClose()}>
                          Close
                        </CButton>
                      </center>
                    </CCardBody>
                  </CCard>
                </CContainer>
              </CForm>
            </div>
          </CCol>
        ) : (
          <></>
        )}
      </CRow>
    </CModal>
  );
}

export default LossChart;
