import {
  CInput,
  CLabel,
  CRow,
  CCol,
  CButton,
  CTabs,
  CTabContent,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CCard,
  CCardHeader,
  CCardBody,
} from "@coreui/react";
import { Satellite, Delete, ArrowForward } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import ORNIcon from "../../../../../../assets/mapsicons/orn.svg";
import ORNPIcon from "../../../../../../assets/mapsicons/ornpassive.svg";

import { MapApi } from "src/Config/API";

function CustomeORNshow({
  data,
  mapDataFinal,
  handleImageView,
  DeleteDataByID,
  PostPoliline,
  getMasteData,
  MapKey,
  PostPolilineContinue,
  forftth

}) {
  const [InputNo, setInputNo] = useState("");
  const [InputImage, setInputImage] = useState(null);
  const [InputMeter, setInputMeter] = useState("");
  const [InputSwapCheck, setInputSwapCheck] = useState(false);

  useEffect(() => {
    setInputNo(data.name);
    setInputMeter(data.meters);
    if (data.core_swap === "0") {
      setInputSwapCheck(false);
    } else {
      setInputSwapCheck(true);
    }
  }, [data]);

  function EditORNById(id, InputValue, InputImage, InputMeter, InputSwapCheck) {
    console.log({
      Input: InputMeter,
    });
    const form = new FormData();
    form.append("name", InputValue);
    form.append("meters", InputMeter);
    form.append("core_swap", InputSwapCheck);

    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
            position.coords.latitude +
            "," +
            position.coords.longitude +
            "/" +
            lat +
            "," +
            lang
          );

          // window.location.replace(
          //   "https://www.google.com/maps/dir/" +
          //     position.coords.latitude +
          //     "," +
          //     position.coords.longitude +
          //     "/" +
          //     lat +
          //     "," +
          //     lang
          // );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  return (
    <>

      {forftth ? <>

        <CCard>

          <CCardHeader>
            <center>
              <h5>ORN</h5>
            </center>
          </CCardHeader>
          <CCardBody>
            <CTabs activeTab="loss">
              <CTabContent>
                <CTabPane data-tab="loss">

                  <table class="table table-bordered table-sm">
                    <tbody>
                      {/* <tr>
                        <td>
                          {" "}
                          <b>Incertion Loss</b>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <CInput
                              // onChange={(v) => setInputMeter(v.target.value)}
                              value={InputMeter}
                            // placeholder="Enter Meter"
                            // readOnly
                            ></CInput>
                            <span class="prefix">DB</span>
                          </div>
                        </td>
                      </tr>{" "} */}
                      {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                        <></>
                      ) : (
                        <>
                          <CLabel style={{ margin: 10, color: "blue" }}>
                            CATV
                          </CLabel>
                          <tr style={{ fontSize: 12 }}>
                            <td>
                              {" "}
                              <center>
                                <b>1550NM</b>
                                <br />
                                Optical Power
                              </center>
                            </td>
                            <td>
                              {" "}
                              <div class="input-box-DB">
                                <CInput
                                  value={data.loss_1550NM}
                                // placeholder="Joint Number"
                                // mask="99.99"
                                ></CInput>
                                <span class="prefix">DB</span>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                      {mapDataFinal.port.wdm_status === 1 ? (
                        <>
                          <CLabel style={{ margin: 10, color: "blue" }}>
                            INTERNET
                          </CLabel>

                          <tr style={{ fontSize: 12 }}>
                            <td>
                              {" "}
                              <center>
                                <b>1490NM</b>
                                <br />
                                Optical Power
                              </center>
                            </td>
                            <td>
                              {" "}
                              <div class="input-box-DB">
                                <CInput
                                  value={data.loss_1490NM}
                                // placeholder="Joint Number"
                                // mask="99.99"
                                ></CInput>
                                <span class="prefix">DB</span>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                  <div>

                    <CRow>
                      <CCol>
                        <center>
                          <h5>RF</h5>
                        </center>
                        <CButton
                          variant="outline"
                          color="primary"
                          block
                          disabled
                          onClick={
                            () => PostPoliline()
                            // PostPoliline(
                            //   data.id,
                            //   parseFloat(data.latlang.lat),
                            //   parseFloat(data.latlang.lang)
                            // )
                          }
                        >
                          Add Coaxial Cable
                        </CButton>
                      </CCol>
                      <CCol>
                        <center>
                          <h5>PON</h5>
                        </center>
                        {data.status === "Disable" ? (
                          <CButton color="primary" block disabled>
                            {/* <img src={drawpoliicon} height="20" /> */}
                            Add FiberLine
                            {/* <ShowChartRounded /> */}
                          </CButton>
                        ) : data.polyline_id !== null ? (
                          <CButton
                            color="primary"
                            block
                            onClick={() => {
                              PostPolilineContinue();
                              // closePopups();
                            }}
                          >
                            FiberLine Continue
                            {/* <img src={drawpoliicon} height="20" /> */}
                          </CButton>
                        ) : (
                          <CButton
                            variant="outline"
                            color="primary"
                            block
                            onClick={
                              () => PostPoliline()
                              // PostPoliline(
                              //   data.id,
                              //   parseFloat(data.latlang.lat),
                              //   parseFloat(data.latlang.lang)
                              // )
                            }
                          >
                            Add FiberLine
                          </CButton>
                        )}
                      </CCol>
                    </CRow>
                  </div>

                </CTabPane>
                <CTabPane data-tab="other_info">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>ORN No</b>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            onChange={(v) => setInputNo(v.target.value)}
                            value={InputNo}
                            placeholder="Enter Loop No"
                          ></CInput>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td>
                          {" "}
                          <p
                            style={{
                              color: "black",
                              fontsize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Upload Image{" "}
                            {data.images !== null ? (
                              <Satellite
                                onClick={
                                  () => handleImageView()
                                  //   handleImageView(
                                  //     data.images
                                  //   )
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </td>
                        <td>
                          {" "}
                          <CInput
                            type="file"
                            placeholder=""
                            onChange={(v) => setInputImage(v.target.files[0])}
                          // value={InputImage}
                          ></CInput>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <b>Enable passive</b>
                        </td>
                        <td>
                          <CLabel class="c-switch c-switch-primary">
                            <CInput
                              type="checkbox"
                              class="c-switch-input"
                              onChange={(v) => {
                                if (InputSwapCheck === true) {
                                  setInputSwapCheck(false);
                                } else {
                                  setInputSwapCheck(true);
                                }
                              }}
                              checked={InputSwapCheck}
                            />
                            <span class="c-switch-slider"></span>
                          </CLabel>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />

                  <CRow>
                    <CCol>
                      <CButton
                        onClick={() =>
                          EditORNById(
                            data.id,
                            InputNo,
                            InputImage,
                            InputMeter,
                            InputSwapCheck ? 1 : 0
                          )
                        }
                        color="primary"
                        block
                      // variant="outline"
                      >
                        {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                        Save{" "}
                      </CButton>
                    </CCol>
                    <CCol>
                      <CButton
                        onClick={() => DeleteDataByID(data.id)}
                        color="info"
                        block
                        variant="outline"
                      >
                        <Delete color="#ff0000" style={{ color: "red" }} />
                      </CButton>
                    </CCol>
                  </CRow>
                  <br />
                </CTabPane>
              </CTabContent>
              <br />
              <CNav variant="tabs" justified="center">
                <CNavItem>
                  <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                    Loss{" "}
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  <CNavLink
                    data-tab="other_info"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {" "}
                    Other Info{" "}
                  </CNavLink>
                </CNavItem>
              </CNav>{" "}
            </CTabs>
          </CCardBody>
        </CCard>

      </> : <Marker
        position={[parseFloat(data.latlang.lat), parseFloat(data.latlang.lang)]}
        icon={
          InputSwapCheck
            ? L.icon({
              iconUrl: ORNPIcon,
              iconRetinaUrl: ORNPIcon,
              iconAnchor: [5, 55],
              popupAnchor: [10, -44],
              iconSize: [60, 90],
            })
            : L.icon({
              iconUrl: ORNIcon,
              iconRetinaUrl: ORNIcon,
              iconAnchor: [5, 55],
              popupAnchor: [10, -44],
              iconSize: [60, 90],
            })
        }
      >
        <>
          {data.images !== null ? (
            <Tooltip sticky>
              <center>{InputNo}</center>

              <img
                src={data.images}
                width="200"
                height={"200"}
              />
            </Tooltip>
          ) : (
            <Tooltip sticky>
              <center>{InputNo}</center>
            </Tooltip>
          )}
        </>
        <Popup minWidth={300} closeButton={false}>
          <center>
            {InputSwapCheck
              ? "Passive Optical Reciver Node"
              : "Active Optical Reciver Node"}
          </center>

          <CTabs activeTab="loss">
            <CTabContent>
              <CTabPane data-tab="loss">
                <br />
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <b>Incertion Loss</b>
                      </td>
                      <td>
                        {" "}
                        <div class="input-box-DB">
                          <CInput
                            // onChange={(v) => setInputMeter(v.target.value)}
                            value={InputMeter}
                          // placeholder="Enter Meter"
                          // readOnly
                          ></CInput>
                          <span class="prefix">DB</span>
                        </div>
                      </td>
                    </tr>{" "}
                    {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                      <></>
                    ) : (
                      <>
                        <CLabel style={{ margin: 10, color: "blue" }}>
                          CATV
                        </CLabel>
                        <tr style={{ fontSize: 12 }}>
                          <td>
                            {" "}
                            <center>
                              <b>1550NM</b>
                              <br />
                              Optical Power
                            </center>
                          </td>
                          <td>
                            {" "}
                            <div class="input-box-DB">
                              <CInput
                                value={data.loss_1550NM}
                              // placeholder="Joint Number"
                              // mask="99.99"
                              ></CInput>
                              <span class="prefix">DB</span>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                    {mapDataFinal.port.wdm_status === 1 ? (
                      <>
                        <CLabel style={{ margin: 10, color: "blue" }}>
                          INTERNET
                        </CLabel>

                        <tr style={{ fontSize: 12 }}>
                          <td>
                            {" "}
                            <center>
                              <b>1490NM</b>
                              <br />
                              Optical Power
                            </center>
                          </td>
                          <td>
                            {" "}
                            <div class="input-box-DB">
                              <CInput
                                value={data.loss_1490NM}
                              // placeholder="Joint Number"
                              // mask="99.99"
                              ></CInput>
                              <span class="prefix">DB</span>
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <div>
                  <br />
                  <CRow>
                    <CCol>
                      <center>
                        <h5>RF</h5>
                      </center>
                      <CButton
                        variant="outline"
                        color="primary"
                        block
                        disabled
                        onClick={
                          () => PostPoliline()
                          // PostPoliline(
                          //   data.id,
                          //   parseFloat(data.latlang.lat),
                          //   parseFloat(data.latlang.lang)
                          // )
                        }
                      >
                        Add Coaxial Cable
                      </CButton>
                    </CCol>
                    <CCol>
                      <center>
                        <h5>PON</h5>
                      </center>
                      {data.status === "Disable" ? (
                        <CButton color="primary" block disabled>
                          {/* <img src={drawpoliicon} height="20" /> */}
                          Add FiberLine
                          {/* <ShowChartRounded /> */}
                        </CButton>
                      ) : data.polyline_id !== null ? (
                        <CButton
                          color="primary"
                          block
                          onClick={() => {
                            PostPolilineContinue();
                            // closePopups();
                          }}
                        >
                          FiberLine Continue
                          {/* <img src={drawpoliicon} height="20" /> */}
                        </CButton>
                      ) : (
                        <CButton
                          variant="outline"
                          color="primary"
                          block
                          onClick={
                            () => PostPoliline()
                            // PostPoliline(
                            //   data.id,
                            //   parseFloat(data.latlang.lat),
                            //   parseFloat(data.latlang.lang)
                            // )
                          }
                        >
                          Add FiberLine
                        </CButton>
                      )}
                    </CCol>
                  </CRow>
                </div>
                <br />
              </CTabPane>
              <CTabPane data-tab="other_info">
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <b>ORN No</b>
                      </td>
                      <td>
                        {" "}
                        <CInput
                          onChange={(v) => setInputNo(v.target.value)}
                          value={InputNo}
                          placeholder="Enter Loop No"
                        ></CInput>
                      </td>
                    </tr>{" "}
                    <tr>
                      <td>
                        {" "}
                        <p
                          style={{
                            color: "black",
                            fontsize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          Upload Image{" "}
                          {data.images !== null ? (
                            <Satellite
                              onClick={
                                () => handleImageView()
                                //   handleImageView(
                                //     data.images
                                //   )
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </p>
                      </td>
                      <td>
                        {" "}
                        <CInput
                          type="file"
                          placeholder=""
                          onChange={(v) => setInputImage(v.target.files[0])}
                        // value={InputImage}
                        ></CInput>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <b>Enable passive</b>
                      </td>
                      <td>
                        <CLabel class="c-switch c-switch-primary">
                          <CInput
                            type="checkbox"
                            class="c-switch-input"
                            onChange={(v) => {
                              if (InputSwapCheck === true) {
                                setInputSwapCheck(false);
                              } else {
                                setInputSwapCheck(true);
                              }
                            }}
                            checked={InputSwapCheck}
                          />
                          <span class="c-switch-slider"></span>
                        </CLabel>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />

                <CRow>
                  <CCol>
                    <CButton
                      onClick={() =>
                        EditORNById(
                          data.id,
                          InputNo,
                          InputImage,
                          InputMeter,
                          InputSwapCheck ? 1 : 0
                        )
                      }
                      color="primary"
                      block
                    // variant="outline"
                    >
                      {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                      Save{" "}
                    </CButton>
                  </CCol>
                  <CCol>
                    <CButton
                      onClick={() => DeleteDataByID(data.id)}
                      color="info"
                      block
                      variant="outline"
                    >
                      <Delete color="#ff0000" style={{ color: "red" }} />
                    </CButton>
                  </CCol>
                </CRow>
                <br />
              </CTabPane>
            </CTabContent>
            <CNav variant="tabs" justified="center">
              <CNavItem>
                <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                  Loss{" "}
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  data-tab="other_info"
                  style={{ fontFamily: "sans-serif" }}
                >
                  {" "}
                  Other Info{" "}
                </CNavLink>
              </CNavItem>
            </CNav>{" "}
          </CTabs>
          <br />
          <CRow>
            <CCol>
              <CButton
                variant="outline"
                color="primary"
                block
                onClick={() => {
                  getMyLocation({
                    lat: parseFloat(mapDataFinal.latlang.lat),
                    lang: parseFloat(mapDataFinal.latlang.lang),
                  });
                  setLoadingModalShowForDirectionOpenMap(true);
                }}
              >
                {LoadingModalShowForDirectionOpenMap
                  ? "Wait Loading..."
                  : "Get Location"}{" "}
                {/* <ArrowForward /> */}
              </CButton>
              <br />
              {getlatforcurruntLocation !== "" ? (
                <center>
                  {" "}
                  <a
                    href={
                      "https://www.google.com/maps/dir/" +
                      getlatforcurruntLocation +
                      "," +
                      getlangforcurruntLocation +
                      "/" +
                      parseFloat(data.latlang.lat) +
                      "," +
                      parseFloat(data.latlang.lang)
                    }
                    target="_BLANK"
                  >
                    Get Directon <ArrowForward />
                  </a>
                </center>
              ) : (
                ""
              )}
            </CCol>
          </CRow>
        </Popup>
      </Marker>}


    </>
  );
}

export default CustomeORNshow;
