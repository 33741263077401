import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CInput,
  CLabel,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import InputMask from "react-input-mask";
import { MapApi } from "src/Config/API";
import { ArrowForward, Delete, Satellite } from "@material-ui/icons";
import jointIcon from "../../../../../../assets/mapsicons/joint.svg";
import L from "leaflet";

export function CustomeJointsShow({
  data,
  getMasteData,
  DeleteDataByID,
  mapDataFinal,
  PostPoliline,
  handleImageView,
  PostPolilineContinue,
  MapKey,
  forftth

}) {
  const [InputData, setInputData] = useState("");
  const [InputMeter, setInputMeter] = useState("");
  const [InputImage, setInputImage] = useState(null);
  const [InputSwapCheck, setInputSwapCheck] = useState(false);
  useEffect(() => {
    setInputData(data.name);
    setInputMeter(data.meters);
    if (data.core_swap === "0") {
      setInputSwapCheck(false);
    } else {
      setInputSwapCheck(true);
    }
  }, [data]);

  function EditJointById(
    id,
    InputValue,
    InputImage,
    InputMeter,
    InputSwapCheck
  ) {
    const form = new FormData();
    form.append("name", InputValue);
    form.append("meters", InputMeter);
    form.append("core_swap", InputSwapCheck);
    if (InputImage !== null) {
      form.append("images", InputImage, InputImage.name);
    }

    console.log(form);
    MapApi.UpdateMasterDataById(id, form, MapKey)
      .then((rsponse) => {
        console.log(rsponse.data);
        getMasteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };
  return (
    <>{forftth ? <>

      <CCard>

        <CCardHeader>
          <center>
            <h5>Joint</h5>
          </center>
        </CCardHeader>
        <CCardBody>
          <CTabs activeTab="loss">
            <CTabContent>
              <CTabPane data-tab="loss">
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <b>Splice loss</b>
                      </td>
                      <td>
                        {" "}
                        <div class="input-box-DB">
                          <InputMask
                            onChange={(v) => setInputMeter(v.target.value)}
                            value={InputMeter}
                            // placeholder="Joint Number"
                            mask="99.99"
                            style={{ width: "100%" }}
                          // className="inputsm"
                          ></InputMask>
                          <span class="prefix">DB</span>
                        </div>
                      </td>
                    </tr>
                    {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                      <></>
                    ) : (
                      <>
                        <CLabel style={{ margin: 10, color: "blue" }}>
                          CATV
                        </CLabel>
                        <tr style={{ fontSize: 12 }}>
                          <td>
                            {" "}
                            <center>
                              <b>
                                1550NM
                                <br />
                              </b>
                              Optical Power
                            </center>
                          </td>
                          <td>
                            {" "}
                            <div class="input-box-DB">
                              <CInput
                                value={data.loss_1550NM}
                              // placeholder="Joint Number"
                              // mask="99.99"
                              ></CInput>
                              <span class="prefix">DB</span>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                    {mapDataFinal.port.wdm_status === 1 ? (
                      <>
                        <CLabel style={{ margin: 10, color: "blue" }}>
                          INTERNET
                        </CLabel>

                        <tr style={{ fontSize: 12 }}>
                          <td>
                            {" "}
                            <center>
                              <b>
                                1490NM
                                <br />
                              </b>
                              Optical Power
                            </center>
                          </td>
                          <td>
                            {" "}
                            <div class="input-box-DB">
                              <CInput
                                value={data.loss_1490NM}
                              // placeholder="Joint Number"
                              // mask="99.99"
                              ></CInput>
                              <span class="prefix">DB</span>
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </CTabPane>
              <CTabPane data-tab="other_info">
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <b>Joint Number</b>
                      </td>
                      <td>
                        {" "}
                        <CInput
                          onChange={(v) => setInputData(v.target.value)}
                          value={InputData}
                          placeholder="Joint Number"
                        ></CInput>
                      </td>
                    </tr>{" "}
                    <tr>
                      <td>
                        {" "}
                        <p
                          style={{
                            color: "black",
                            fontsize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          Upload Image{" "}
                          {data.images !== null ? (
                            <Satellite
                              onClick={
                                () => handleImageView()
                                // handleImageView(
                                //   data.images
                                // )
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </p>
                      </td>
                      <td>
                        <CInput
                          type="file"
                          placeholder=""
                          onChange={(v) => setInputImage(v.target.files[0])}
                        // value={InputImage}
                        ></CInput>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <b>Core Swap</b>
                      </td>
                      <td>
                        <CLabel class="c-switch c-switch-primary">
                          <CInput
                            type="checkbox"
                            class="c-switch-input"
                            onChange={(v) => {
                              if (InputSwapCheck === true) {
                                setInputSwapCheck(false);
                              } else {
                                setInputSwapCheck(true);
                              }
                            }}
                            checked={InputSwapCheck}
                          />
                          <span class="c-switch-slider"></span>
                        </CLabel>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CTabPane>
            </CTabContent>
            <table class="table table-bordered table-sm">
              <tbody>
                <tr>
                  <td>  <CButton
                    onClick={() =>
                      EditJointById(
                        data.id,
                        InputData,
                        InputImage,
                        InputMeter,
                        InputSwapCheck ? 1 : 0
                      )
                    }
                    color="primary"
                    block
                  // variant="outline"
                  >
                    {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                    Save{" "}
                  </CButton></td>
                  <td>
                    <CButton
                      onClick={() => DeleteDataByID()}
                      color="info"
                      block
                      variant="outline"
                    >
                      <Delete color="#ff0000" style={{ color: "red" }} />
                    </CButton>
                  </td>
                </tr>
              </tbody>
            </table>

            <CNav variant="tabs" justified="center">
              <CNavItem>
                <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                  Loss{" "}
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  data-tab="other_info"
                  style={{ fontFamily: "sans-serif" }}
                >
                  Other Info
                </CNavLink>
              </CNavItem>
            </CNav>
          </CTabs>
        </CCardBody>
      </CCard>



    </> : <Marker
      position={[parseFloat(data.latlang.lat), parseFloat(data.latlang.lang)]}
      icon={L.icon({
        iconUrl: jointIcon,
        iconRetinaUrl: jointIcon,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [60, 90],
      })}
    >
      <>
        {data.images !== null ? (
          <Tooltip sticky>
            <center>{InputData}</center>

            <img
              src={data.images}
              width="200"
              height={"200"}
            />
          </Tooltip>
        ) : (
          <Tooltip sticky>
            <center>{InputData}</center>
          </Tooltip>
        )}
      </>

      <Popup minWidth={300} closeButton={false}>
        <center>
          <h5>Joint</h5>
        </center>

        <CTabs activeTab="loss">
          <CTabContent>
            <CTabPane data-tab="loss">
              <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td>
                      <b>Splice loss</b>
                    </td>
                    <td>
                      {" "}
                      <div class="input-box-DB">
                        <InputMask
                          onChange={(v) => setInputMeter(v.target.value)}
                          value={InputMeter}
                          // placeholder="Joint Number"
                          mask="99.99"
                          style={{ width: "100%" }}
                        // className="inputsm"
                        ></InputMask>
                        <span class="prefix">DB</span>
                      </div>
                    </td>
                  </tr>
                  {MapKey === "gpon_olt" || MapKey === "epon_olt" ? (
                    <></>
                  ) : (
                    <>
                      <CLabel style={{ margin: 10, color: "blue" }}>
                        CATV
                      </CLabel>
                      <tr style={{ fontSize: 12 }}>
                        <td>
                          {" "}
                          <center>
                            <b>
                              1550NM
                              <br />
                            </b>
                            Optical Power
                          </center>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <CInput
                              value={data.loss_1550NM}
                            // placeholder="Joint Number"
                            // mask="99.99"
                            ></CInput>
                            <span class="prefix">DB</span>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  {mapDataFinal.port.wdm_status === 1 ? (
                    <>
                      <CLabel style={{ margin: 10, color: "blue" }}>
                        INTERNET
                      </CLabel>

                      <tr style={{ fontSize: 12 }}>
                        <td>
                          {" "}
                          <center>
                            <b>
                              1490NM
                              <br />
                            </b>
                            Optical Power
                          </center>
                        </td>
                        <td>
                          {" "}
                          <div class="input-box-DB">
                            <CInput
                              value={data.loss_1490NM}
                            // placeholder="Joint Number"
                            // mask="99.99"
                            ></CInput>
                            <span class="prefix">DB</span>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </CTabPane>
            <CTabPane data-tab="other_info">
              <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <b>Joint Number</b>
                    </td>
                    <td>
                      {" "}
                      <CInput
                        onChange={(v) => setInputData(v.target.value)}
                        value={InputData}
                        placeholder="Joint Number"
                      ></CInput>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      {" "}
                      <p
                        style={{
                          color: "black",
                          fontsize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Upload Image{" "}
                        {data.images !== null ? (
                          <Satellite
                            onClick={
                              () => handleImageView()
                              // handleImageView(
                              //   data.images
                              // )
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </p>
                    </td>
                    <td>
                      <CInput
                        type="file"
                        placeholder=""
                        onChange={(v) => setInputImage(v.target.files[0])}
                      // value={InputImage}
                      ></CInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <b>Core Swap</b>
                    </td>
                    <td>
                      <CLabel class="c-switch c-switch-primary">
                        <CInput
                          type="checkbox"
                          class="c-switch-input"
                          onChange={(v) => {
                            if (InputSwapCheck === true) {
                              setInputSwapCheck(false);
                            } else {
                              setInputSwapCheck(true);
                            }
                          }}
                          checked={InputSwapCheck}
                        />
                        <span class="c-switch-slider"></span>
                      </CLabel>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CTabPane>
          </CTabContent>

          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>  <CButton
                  onClick={() =>
                    EditJointById(
                      data.id,
                      InputData,
                      InputImage,
                      InputMeter,
                      InputSwapCheck ? 1 : 0
                    )
                  }
                  color="primary"
                  block
                // variant="outline"
                >
                  {/* <Check color="#ff0000" style={{ color: "white" }} /> */}
                  Save{" "}
                </CButton></td>
                <td>
                  <CButton
                    onClick={() => DeleteDataByID()}
                    color="info"
                    block
                    variant="outline"
                  >
                    <Delete color="#ff0000" style={{ color: "red" }} />
                  </CButton>
                </td>
              </tr>
              <tr>
                <td>    {data.status === "Disable" ? (
                  <CButton color="primary" block disabled>
                    {/* <img src={drawpoliicon} height="20" /> */}
                    Add FiberLine
                    {/* <ShowChartRounded /> */}
                  </CButton>
                ) : data.polyline_id !== null ? (
                  <CButton
                    color="primary"
                    block
                    onClick={() => {
                      PostPolilineContinue();
                      // closePopups();
                    }}
                  >
                    FiberLine Continue
                    {/* <img src={drawpoliicon} height="20" /> */}
                  </CButton>
                ) : (
                  <CButton
                    variant="outline"
                    color="primary"
                    block
                    onClick={() => PostPoliline()}
                  >
                    Add FiberLine
                  </CButton>
                )}</td>
                <td> <CButton
                  variant="outline"
                  color="primary"
                  block
                  onClick={() => {
                    getMyLocation({
                      lat: parseFloat(mapDataFinal.latlang.lat),
                      lang: parseFloat(mapDataFinal.latlang.lang),
                    });
                    setLoadingModalShowForDirectionOpenMap(true);
                  }}
                >
                  {LoadingModalShowForDirectionOpenMap
                    ? "Wait Loading..."
                    : "Get Location"}{" "}
                  {/* <ArrowForward /> */}
                </CButton>
                  <br />
                  {getlatforcurruntLocation !== "" ? (
                    <center>
                      {" "}
                      <a
                        href={
                          "https://www.google.com/maps/dir/" +
                          getlatforcurruntLocation +
                          "," +
                          getlangforcurruntLocation +
                          "/" +
                          parseFloat(data.latlang.lat) +
                          "," +
                          parseFloat(data.latlang.lang)
                        }
                        target="_BLANK"
                      >
                        Get Directon <ArrowForward />
                      </a>
                    </center>
                  ) : (
                    ""
                  )}</td>
              </tr>
            </tbody>
          </table>

          <CNav variant="tabs" justified="center">
            <CNavItem>
              <CNavLink data-tab="loss" style={{ fontFamily: "sans-serif" }}>
                Loss{" "}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="other_info"
                style={{ fontFamily: "sans-serif" }}
              >
                Other Info
              </CNavLink>
            </CNavItem>
          </CNav>
        </CTabs>

      </Popup>
    </Marker>}

    </>
  );
}
