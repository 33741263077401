import axios from "axios";
class Api {
  static _axios = null;
  static instance() {
    if (this._axios == null) {
      this._axios = axios.create({
        baseURL: "https://admin.navyatechs.com/api/",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
          apitoken: "navya",
          secure: false,
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
    }
    return this._axios;
  }
  static instanceWithoutAuth() {
    if (this._axios == null) {
      this._axios = axios.create({
        baseURL: "https://admin.navyatechs.com/api/",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
          apitoken: "navya",
          secure: false,
        },
      });
    }
    return this._axios;
  }
  static instanceForPayment() {
    if (this._axios == null) {
      this._axios = axios.create({
        baseURL: "https://test.ccavenue.com/",
        headers: {
          // "Content-Type": "application/json",
          // Accept: "application/json, text/plain, */*",
          // apitoken: "navya",
          // secure: false,
        },
      });
    }
    return this._axios;
  }
  static toJSON(data) {
    var object = {};
    data.forEach((value, key) => (object[key] = value));
    return object;
  }

}
export class ContactApi {
  static Contact(Details) {
    return Api.instance().post("/contact", Details);
  }
}
export class LoginApi {
  static requestOTP(mobileNo) {
    return Api.instance().get("otp/request?user=" + mobileNo);
  }
  static verifiedOTP(mobileNo, otp) {
    return Api.instance().post("otp/verify?user=" + mobileNo + "&otp=" + otp);
  }
}
export class ProfileApi {
  static getPrfileData(id) {
    return Api.instance().get("operator/profile");
  }
  static updatePrfileData(data) {
    return Api.instance().post("operator/profile", data);
  } z
}
export class RegisterAPI {
  static requestOTP(registerData) {
    return Api.instance().post("request-otp", registerData);
  }
  static register(registerData) {
    return Api.instance().post("auth/register", registerData);
  }
  static getLocationData(query) {
    return Api.instance().get(
      "https://maps.googleapis.com/maps/api/place/textsearch/json?query=" +
      query +
      "&key=AIzaSyC8bslqcH0T5GcoMPByf04SeajCKZkEekw"
    );
  }
}
export class DeviceApi {
  static getdevicesData() {
    return Api.instance().get("operator/categories");
  }

  static getdevicesDataByCategory(slug) {
    return Api.instance().get("operator/category/" + slug + "/devices");
  }

  static getdevicesDataIndivitual(slug, id) {
    return Api.instance().get("operator/category/" + slug + "/devices/" + id);
  }

  static getcatDataIndivitual(slug) {
    return Api.instance().get("operator/category/" + slug);
  }

  static getportdevicesData() {
    return Api.instance().get("operator/devices");
  }
  static addDevicePort(deviceData) {
    return Api.instance().post("operator/devices", deviceData);
  }
  static addPort(id, data) {
    return Api.instance().post("operator/device/" + id + "/add_port", data);
  }

  static portUpdateStatus(id, statusData) {
    return Api.instance().put("operator/port/" + id, statusData);
  }
}

export class LossAPI {
  static getLossData(cat_id) {
    return Api.instance().get("operator/loss_data?cat_id=" + cat_id);
  }
  static getLossAllData() {
    return Api.instance().get("operator/loss_data");
  }
  static UpdateLossData(details) {
    return Api.instance().post("operator/loss/items", details);
  }
  static resetLoss(details) {
    return Api.instance().post("loss/loss_data/reset", details);
  }
}

export class ReportAPI {
  static getBillReport() {
    return Api.instance().get("operator/billing_report");
  }
  static getPortReport() {
    return Api.instance().get("operator/port_report");
  }
  static getDeviceReport() {
    return Api.instance().get("operator/device_report");
  }
}
export class PackagesAPI {
  static getPackagesAllData() {
    return Api.instanceWithoutAuth().get("/packages");
  }
  static getPackagesForUpdgrad() {
    return Api.instance().get("/operator/packages");
  }
  static getCalculationForUpdgrad(packageId, planType) {
    return Api.instance().get("/payment/upgradeplan?package_id=" + packageId + "&plan_type=" + planType);
  }
  static getStateData() {
    return Api.instance().get("/state_codes");
  }
  static getPackagesByID() {
    return Api.instance().get("/payment/current_plan");
  }
  static updatePlan(Details) {
    return Api.instance().post("/payment/update_plan", Details);
  }
  static addUserpackage(Details) {
    return Api.instance().post("/payment/payment", Details);
  }

  static OpenCCAvenueFrame(Details) {
    return Api.instanceForPayment().post("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction", Details);
  }


  static addOrder(Details) {
    return Api.instance().post("/payment/make_order", Details);
  }
  static addPayment(Details) {
    return Api.instance().post("/payment/make_payment", Details);
  }
  static checkCoupons(coupon) {
    return Api.instance().get("/validate_coupon?coupon=" + coupon);
  }
  static BillingHistory() {
    return Api.instance().get("/payment/billing_history");
  }
}

export class FeedBackAPI {
  static addFeedbackData(Details) {
    return Api.instance().post("/operator/feedback", Details);
  }
  static getFeedbackData(Details) {
    return Api.instance().get("/operator/feedback", Details);
  }
}

export class CustomersLocationAPI {
  static addCustomersData(Details) {
    return Api.instance().post("/operator/customers", Details);
  }
  static getCustomersData(Details) {
    return Api.instance().get("/operator/customers", Details);
  }
}

export class MapApi {
  static RefreshCalculationData(Port_id, key) {
    return Api.instance().get("/" + key + "/devices/" + Port_id + "/update");
  }
  static addMaster(Details, key) {
    return Api.instance().post("/" + key + "/masters", Details);  
  }
  static addMasterFromDraw(Details, key) {
    return Api.instance().post("/" + key + "/masters", Details);  
  }
  static getMaster(key) {
    return Api.instance().get("/" + key + "/masters");
  }
  static getMasterDataById(id, key) {
    return Api.instance().get("/" + key + "/masters/" + id);
  }
  static UpdateMasterDataById(id, details, key) {
    return Api.instance().post("/" + key + "/masters/" + id, details);
  }
  static updateLatLgnByIdForDeviceType(id, details, key) {
    return Api.instance().put("/" + key + "/masters/" + id + "/latlang", details)
  }
  static DeleteMasterDataById(id, key) {
    return Api.instance().delete("/" + key + "/masters/" + id);
  }
  static getMapAllData(port_id, key) {
    return Api.instance().get("/" + key + "/devices/" + port_id);
  }
  static getMapAllDataForSearch(port_id, key) {
    return Api.instance().get("/" + key + "/devices/" + port_id + "/types");
  }
  static getMapAllFttxData(port_id, key,fttx_id) {
    return Api.instance().get("/" + key + "/devices/" + port_id + "?fttx=true&fttx_id="+fttx_id);
  }
  static getMapFiberLineAllData(port_id, key) {
    return Api.instance().get("/" + key + "/devices/" + port_id + "/polylines");
  }

  static getUpdateMapAllData(port_id, key) {
    return Api.instance().get("/" + key + "/devices/" + port_id + "/update");
  }
  static getMasterByPortId(key) {
    return Api.instance().get("/" + key + "/masters");
  }
  static getCustomerDataByOrId(id, key) {
    return Api.instance().get(
      "/" + key + "/customers?optical_receiver_id=" + id
    );
  }
  static addCustomerDataByOrId(details, key) {
    return Api.instance().post("/" + key + "/customers", details);
  }
  static addFiberTubeColorByFID(details, key) {
    return Api.instance().post("/" + key + "/polyline", details);
  }

  static DeleteFiberTubeColorByFID(id, key) {
    return Api.instance().delete("/" + key + "/polyline/" + id);
  }
  static DeleteCustomerDataByOrId(id, key) {
    return Api.instance().delete("/" + key + "/customers/" + id);
  }
  static EditCustomerDataByOrId(id, details, key) {
    return Api.instance().put("/" + key + "/customers/" + id, details);
  }
  static addPolilineLatLang(Details, key) {
    return Api.instance().post("/" + key + "/lat_longs", Details);
  }
  static ForwordPoliline(id, key) {
    return Api.instance().get("/" + key + "/polyline/" + id + "/forward");
  }
  static BackWordPoliline(id, key) {
    return Api.instance().get("/" + key + "/polyline/" + id + "/back");
  }
  static getONUIcons() {
    return Api.instance().get("/map2/get_icon");
  }

  static getCouplerIcon(min, max, format) {
    return "https://admin.navyatechs.com/api/map2/images/coupler?min=" + min + "&max=" + max + "&format=" + format;
  }

  static getSplitterIcon(name) {
    return "https://admin.navyatechs.com/api/map2/images/splitter?name=" + name;
  }

  static addCustomersData(port, Details) {
    return Api.instance().post("/operator/port_customer", Details);
  }
  static getCustomersData(port, Details) {
    return Api.instance().get("/operator/port_customers/" + port);
  }
  static deleteCustomersDataById(id) {
    return Api.instance().delete("/operator/port_customer/" + id);
  }
  static editCustomersDataById(id, Details) {
    return Api.instance().post("/operator/port_customer/" + id, Details);
  }




  // static getSplitterIcon(name) {
  //   return Api.instance().get("/map2/images/splitter?name=" + name);
  // }
}
