import React, { useEffect, useRef, useState } from "react";

import {
  MapContainer,
  Marker,
  Popup,
  Tooltip,
  TileLayer,
  useMapEvents,
  Polyline,
  LayersControl,
} from "react-leaflet";

import "../../../testdata/MapObject.css";
import customerIcon from "../../../../../assets/mapsicons/personcustomer.svg";

import InputMask from "react-input-mask";
// import {SidebarMap} from "./sidebarMap/SidebarMap";
import {
  CButton,
  CCardBody,
  CCol,
  CInput,
  CLabel,
  CModalHeader,
  CModal,
  CRow,
  CTooltip,
  CTabs,
  CTabPane,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CCard,
  CCardHeader,
} from "@coreui/react";

import mainroom from "../../../../../assets/mapsicons/mainicon.svg";

import L from 'leaflet';

import { Backdrop } from "@material-ui/core";

import { AddLocation, ArrowForward, Close, Delete } from "@material-ui/icons";

import { MapApi, LossAPI } from "src/Config/API";

import maploadingg from "../../../../../assets/Loading/maploadingg.gif";
import swal from "sweetalert";

import { ToastContainer } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import Customers from "../../../testdata/optical_reciver/customers";
import LossChart from "../../../testdata/couplor/loss_chart";
import PLCLossChart from "../../../testdata/plc_splitter/plc_loss_chart";
import ImageModal from "../../../testdata/components/image_model";
import OnuList from "../../../testdata/ONU/onuList";
import ShowFiberLine from "../components/FiberLine/showFiberLine";
import { CustomeCouplerShow } from "../components/Coupler/customecouplorShow";
import { CustomePLCSShow } from "../components/PLC/customePLCShow";
import { CustomeJointsShow } from "../components/Joint/customJointShow";
import CustomeONUshow from "../components/ONU/customeONUshow";
import { CustomeLoopsShow } from "../components/loops/customLoopsShow";
import CustomeORNshow from "../components/ORN/customeORNshow";
import { CustomeORShow } from "../components/optical Reciver/customORShow";
import { Link, Redirect, useHistory } from "react-router-dom";
import Mapdiractionloading from "../components/loading/mapdiractionloading";
import TheHeaderPlanScreen from "src/containers/TheHeaderplansscreen";
import MapsidePanel from "src/views/components/mapsidePanel";
import CustomeFTTxshow from "../components/FTTx/customeFTTxshow";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function MapforGEPon({ match }) {
  const [map, setMap] = useState(null);
  const [MapKey, setMapKey] = useState("epon_olt" ?? "");
  const mapRef = useRef();
  const location = useLocation();

  // var finalMeters = null;
  // const actions = [];
  const [actions, setactions] = useState([]);

  const [EnableMarkerModal, setEnableMarkerModal] = useState(false);
  const [ShowModalForCustomer, setShowModalForCustomer] = useState(false);

  const [mapDataFinal, setmapDataFinal] = useState(null);
  const [MapFiberLineData, setMapFiberLineData] = useState(null);
  const [EnableCoupler, setEnableCoupler] = useState(false);
  const [selectedDeviceForhighlight, setselectedDeviceForhighlight] =
    useState("");

  const [EnableONU, setEnableONU] = useState(false);

  const [checkPolyLines, setcheckPolyLines] = useState(false);
  const [checkPLCS, setcheckPLCS] = useState(false);
  const [CheckFTTx, setCheckFTTx] = useState(false);
  const [FTTxOBJ, setFTTxOBJ] = useState(null);
  const [EnableFTTXModal, setEnableFTTXModal] = useState(false);
  const [SearchDataMaster, setSearchDataMaster] = useState([]);

  const [CheckJoint, setCheckJoint] = useState(false);
  const [CheckLoop, setCheckLoop] = useState(false);
  const [CheckORN, setCheckORN] = useState(false);

  const [CheckOR, setCheckOR] = useState(false);
  const [CheckORP, setCheckORP] = useState(false);

  const [PoliId, setPoliId] = useState(0);

  const [CustomerID, setCustomerID] = useState(0);
  const [CustomersListPoints, setCustomersListPoints] = useState([]);

  //colleps

  useEffect(() => {
    setMapKey("epon_olt");
    console.log(MapKey);
    if (MapKey !== undefined) {
      // getMasteData(MapKey);
      MapApi.getUpdateMapAllData(match.params.portnumber, MapKey)
        .then((response) => {
          getMasteData(MapKey);
          getMasteDataForSearch();
          getPoliLineMasterData();
          console.log(response.data);
        })
        .catch((error) => {});
    }
    getJointLossSplices(4);
  }, []);

  const [WDMStatus, setWDMStatus] = useState(false);
  const getMasteData = () => {
    MapApi.getMapAllData(match.params.portnumber, MapKey)
      .then((response) => {
        setselectedDeviceForhighlight("");

        setmapDataFinal(response.data.data);
        if (response.data.data.port.wdm_status === 0) {
          setWDMStatus(true);
          setactions([
            {
              icon: "C",
              name: "Coupler",
              onClick: () => setEnableCoupler(true),
            },
            {
              icon: "P",
              name: "PLC Splitter",
              onClick: () => setcheckPLCS(true),
            },
            { icon: "J", name: "Joint", onClick: () => setCheckJoint(true) },
            { icon: "L", name: "Loop", onClick: () => setCheckLoop(true) },

            {
              icon: "OR",
              name: "Optical Receiver",
              onClick: () => setCheckOR(true),
            },
            {
              icon: "OR-P",
              name: "Optical Receiver Passive",
              onClick: () => setCheckORP(true),
            },
            {
              icon: "MR",
              name: "Marker Enable Disable",
              onClick: () => setEnableMarkerModal(true),
            },
          ]);
        } else {
          setWDMStatus(false);
          setactions([
            { icon: "O", name: "ONU", onClick: () => setEnableONU(true) },
            {
              icon: "C",
              name: "Coupler",
              onClick: () => setEnableCoupler(true),
            },
            {
              icon: "P",
              name: "PLC Splitter",
              onClick: () => setcheckPLCS(true),
            },
            { icon: "J", name: "Joint", onClick: () => setCheckJoint(true) },
            { icon: "L", name: "Loop", onClick: () => setCheckLoop(true) },
            {
              icon: "ORN",
              name: "Optical reciver node",
              onClick: () => setCheckORN(true),
            },
            {
              icon: "OR",
              name: "Optical Receiver",
              onClick: () => setCheckOR(true),
            },
            {
              icon: "OR-P",
              name: "Optical Receiver Passive",
              onClick: () => setCheckORP(true),
            },
            {
              icon: "MR",
              name: "Marker Enable Disable",
              onClick: () => setEnableMarkerModal(true),
            },
          ]);
        }

        console.log(response.data.data);
        console.log(mapDataFinal);
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Warning!",
          text: error.response.data.error,
          icon: "warning",
          button: "Okay!",
        });
      });
  };
  const getPoliLineMasterData = () => {
    MapApi.getMapFiberLineAllData(match.params.portnumber, MapKey)
      .then((response) => {
        setMapFiberLineData(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePolyLine = (id) => {
    MapApi.getMasterDataById(id, MapKey)
      .then((response) => {
        console.log(response.data.data);
        console.log(mapDataFinal);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleONUList() {
    sethandleONUListModal(!handleONUListModal);
  }

  const [MapDataFinall, setMapDataFinall] = useState({
    FiberLine: [],
    Couplers: [],
    Joints: [],
  });

  const [PoliLineLocations, setPoliLineLocations] = useState([
    // [23.2326102, 69.6436178, { id: 1 }],
    // [23.23250154083, 69.643681911902, { id: 2 }],
  ]);

  const [addFiberLineCheck, setaddFiberLineCheck] = useState(false);
  const [AddCouplor, setAddCouplor] = useState([]);
  const [AddJoints, setAddJoints] = useState([]);
  const [CouplorIDForSaveLossData, setCouplorIDForSaveLossData] = useState(0);
  const [PLCSIDForSaveLossData, setPLCSIDForSaveLossData] = useState(0);
  const [getlatforcurruntLocation, setgetlatforcurruntLocation] = useState("");
  const [getlangforcurruntLocation, setgetlangforcurruntLocation] =
    useState("");

  //getlocationCurrent
  const [
    LoadingModalShowForDirectionOpenMap,
    setLoadingModalShowForDirectionOpenMap,
  ] = useState(false);
  const getMyLocation = ({ lat, lang }) => {
    const location = window.navigator && window.navigator.geolocation;
    // setLoadingModalShowForDirectionOpenMap(true);
    var options = {
      enableHighAccuracy: true,
    };
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLoadingModalShowForDirectionOpenMap(false);
          setgetlatforcurruntLocation(position.coords.latitude);
          setgetlangforcurruntLocation(position.coords.longitude);

          console.log(
            "https://www.google.com/maps/dir/" +
              position.coords.latitude +
              "," +
              position.coords.longitude +
              "/" +
              lat +
              "," +
              lang
          );

          // window.location.replace(
          //   "https://www.google.com/maps/dir/" +
          //     position.coords.latitude +
          //     "," +
          //     position.coords.longitude +
          //     "/" +
          //     lat +
          //     "," +
          //     lang
          // );
        },
        (error) => {
          console.log(error);
        },
        options
      );
    }
  };

  function AddMainMarker() {
    const [OutPut, setOutPut] = useState(mapDataFinal.value1);
    const [WdmOutput, setWdmOutput] = useState(mapDataFinal.value2);
    const [GponEPonStatus, setGponEPonStatus] = useState("0");

    useEffect(() => {
      setGponEPonStatus(mapDataFinal.map_type);
    }, []);

    return (
      <div>
        <ToastContainer />
        <Marker
          position={[mapDataFinal.latlang.lat, mapDataFinal.latlang.lang]}
          icon={L.icon({
            iconUrl: mainroom,
            iconRetinaUrl: mainroom,
            iconAnchor: [5, 55],
            popupAnchor: [10, -44],
            iconSize: [75, 105],
          })}
        >
          <Popup closeOnEscapeKey closeButton={false} minWidth={300}>
            {/* Port No : {match.params.portnumber} */}
            <center>
              {" "}
              <h3>Gepon/Epon-OLT</h3>
            </center>
            <CTabs activeTab={MapKey === "epon_olt" ? "page_2" : "page_1"}>
              <CTabContent>
                {MapKey === "epon_olt" ? (
                  <></>
                ) : (
                  <>
                    <CTabPane data-tab="page_1">
                      <CRow
                        style={{
                          fontSize: 16,
                          color: "black",
                        }}
                      >
                        <CCol>Enter Output Power</CCol>
                      </CRow>
                      <div class="input-box-DB">
                        <InputMask
                          style={{ width: "100%" }}
                          mask="99.99"
                          value={OutPut}
                          onChange={(v) => setOutPut(v.target.value)}
                        ></InputMask>{" "}
                        <span class="prefix">DB</span>
                      </div>
                      <CRow>
                        <CCol>
                          <div style={{ fontsize: 4, color: "red" }}>
                            upto 99.99
                          </div>
                        </CCol>
                        <CCol>
                          <div style={{ fontsize: 4, color: "blue" }}>
                            <center> 1 DB Margin Loss</center>
                          </div>
                        </CCol>
                      </CRow>
                      <br />

                      <CRow
                        style={{
                          color: "blue",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <CCol>
                          Please Check Your Output Optical Power In Your Power
                          Meter
                        </CCol>
                      </CRow>
                    </CTabPane>
                  </>
                )}
                <CTabPane data-tab="page_2">
                  {mapDataFinal.port.wdm_status === 1 ? (
                    <>
                      <div>
                        <CRow
                          style={{
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <CCol>Add WDM(OLT Power Internet-1490 NM)</CCol>
                        </CRow>
                        <div class="input-box-DB">
                          <InputMask
                            style={{ width: "100%" }}
                            mask="99.99"
                            value={WdmOutput}
                            onChange={(v) => setWdmOutput(v.target.value)}
                          ></InputMask>{" "}
                          <span class="prefix">DB</span>
                        </div>
                        <CRow>
                          <CCol>
                            <div style={{ fontsize: 4, color: "red" }}>
                              upto 99.99
                            </div>
                          </CCol>
                          <CCol>
                            <div style={{ fontsize: 4, color: "blue" }}>
                              <center> 1 DB Margin Loss</center>
                            </div>
                          </CCol>
                        </CRow>
                        <br />
                        <CRow
                          style={{
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <CCol>1310 NM</CCol>
                        </CRow>
                        <CButton
                          onClick={() => {
                            handleONUList();
                          }}
                          type="button"
                          block
                          color="info"
                          variant="outline"
                        >
                          Show ONU <ArrowForward />
                        </CButton>
                        <br />
                      </div>
                    </>
                  ) : (
                    <>Show Internet Detials you have to Unable WDM</>
                  )}
                </CTabPane>
                <br />
                <CRow>
                  <CCol>
                    <CButton
                      color="primary"
                      block
                      onClick={() =>
                        EditMainRoom(
                          mapDataFinal.id,
                          OutPut,
                          WdmOutput,
                          GponEPonStatus
                        )
                      }
                    >
                      Save
                    </CButton>
                  </CCol>
                </CRow>
                <br />
                <CRow>
                  {" "}
                  <CCol>
                    {mapDataFinal.status === "Disable" ? (
                      <CButton color="primary" block disabled>
                        {/* <img src={drawpoliicon} height="20" /> */}
                        Add FiberLine
                        {/* <ShowChartRounded /> */}
                      </CButton>
                    ) : mapDataFinal.polyline_id !== null ? (
                      <CButton
                        variant="outline"
                        color="primary"
                        block
                        onClick={() => {
                          setPoliId(mapDataFinal.polyline_id);
                          setcheckPolyLines(true);
                        }}
                      >
                        Continue FiberLine
                      </CButton>
                    ) : (
                      <CButton
                        variant="outline"
                        color="primary"
                        block
                        onClick={() =>
                          PostPoliline(
                            mapDataFinal.id,
                            mapDataFinal.latlang.lat,
                            mapDataFinal.latlang.lang
                          )
                        }
                      >
                        Add FiberLine
                      </CButton>
                    )}
                  </CCol>
                </CRow>
                <br />
              </CTabContent>
              <CNav variant="tabs" justified="center">
                {MapKey === "epon_olt" ? (
                  <></>
                ) : (
                  <>
                    <CNavItem>
                      <CNavLink
                        data-tab="page_1"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        CATV
                      </CNavLink>
                    </CNavItem>
                  </>
                )}

                <CNavItem>
                  <CNavLink
                    data-tab="page_2"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Internet
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CTabs>
            <CCardBody>
              <CRow>
                <CCol>
                  <CButton
                    variant="outline"
                    color="primary"
                    block
                    onClick={() => {
                      getMyLocation({
                        lat: parseFloat(mapDataFinal.latlang.lat),
                        lang: parseFloat(mapDataFinal.latlang.lang),
                      });
                      setLoadingModalShowForDirectionOpenMap(true);
                    }}
                  >
                    {LoadingModalShowForDirectionOpenMap
                      ? "Wait Loading..."
                      : "Get Location"}{" "}
                    {/* <ArrowForward /> */}
                  </CButton>
                  <br />
                  {getlatforcurruntLocation !== "" ? (
                    <center>
                      {" "}
                      <a
                        href={
                          "https://www.google.com/maps/dir/" +
                          getlatforcurruntLocation +
                          "," +
                          getlangforcurruntLocation +
                          "/" +
                          parseFloat(mapDataFinal.latlang.lat) +
                          "," +
                          parseFloat(mapDataFinal.latlang.lang)
                        }
                        target="_BLANK"
                      >
                        Get Directon <ArrowForward />
                      </a>
                    </center>
                  ) : (
                    ""
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </Popup>
        </Marker>
      </div>
    );
  }
  function EditMainRoom(id, InputData, WdmInputData, GponEPonStatus) {
    MapApi.UpdateMasterDataById(
      id,
      {
        value2: WdmInputData,
        value1: InputData,
        map_type: GponEPonStatus,
      },
      MapKey
    )
      .then((response) => {
        getMasteData();
      })
      .catch((error) => {});
  }
  function PostPoliline(pointID, lat, lang) {
    var data = {
      master_id: mapDataFinal.id,
      type: "polyline",
      color: "#0066AA",
      point_id: pointID,
      meters: 0,
      data: {
        line_width: "5",
        cable_type: "FTTH",
        fiber_core: "1F",
      },
    };
    MapApi.addMaster(data, MapKey)
      .then((response) => {
        console.log(response.data);
        setPoliId(response.data.data.id);
        // map.current.leafletElement.closePopup();
        MapApi.addPolilineLatLang(
          {
            polyline_id: response.data.data.id,
            lat: lat,
            lang: lang,
          },
          MapKey
        )
          .then((response) => {
            console.log(response.data);
            setcheckPolyLines(true);
            updatePolyLine(response.data.id);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        swal({
          title: "Ops!",
          text: error.response.data.error,
          icon: "warning",
          button: "Okay!",
        });
        console.log(error);
      });
  }
  function PostPolilineContinue() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;

        MapApi.addPolilineLatLang(
          {
            polyline_id: PoliId,
            lat: lat,
            lang: lng,
          },
          MapKey
        )
          .then((response) => {
            console.log(response.data);
            getDistance(PoliId);
          })
          .catch((error) => {
            // swal({
            //   title: "Warning!",
            //   text: error.response.data.error,
            //   icon: "warning",
            //   button: "Okay!",
            // });
            console.log(error);
          });
      },
    });

    return null;
  }

  function AddFiberLine() {
    return <Polyline positions={PoliLineLocations} color="#ce1010" />;
  }
  function AddDataFiberLine() {
    const [position, setPosition] = useState({ latitude: 0, longitude: 0 });
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        setPosition({
          latitude: lat,
          longitude: lng,
        });
        if (addFiberLineCheck === true) {
          PoliLineLocations.push([lat, lng]);
          setPoliLineLocations([...PoliLineLocations]);
        }
      },
    });
    console.log(PoliLineLocations);
    console.log(MapDataFinall);

    return null;
  }

  function AddPoliMarkers() {
    return (
      <Marker
        title="couplor"
        position={PoliLineLocations[PoliLineLocations.length - 1]}
        // icon={mapIcon}
      >
        <Popup closeOnEscapeKey minWidth={300} closeButton={false}>
          {" "}
          <CRow>
            <CCol>
              <CButton
                variant="outline"
                color="warning"
                block
                onClick={() => {
                  AddCouplor.push(
                    PoliLineLocations[PoliLineLocations.length - 1]
                  );
                  setAddCouplor([...AddCouplor]);
                  MapDataFinall.Couplers.push(
                    PoliLineLocations[PoliLineLocations.length - 1]
                  );
                  setMapDataFinall({ ...MapDataFinall });
                }}
              >
                Add Coupler
              </CButton>
            </CCol>
            <CCol>
              <CButton variant="outline" color="info" block>
                Add PLC
              </CButton>
            </CCol>
          </CRow>
          <br />
          <CRow>
            {" "}
            <CCol>
              <CButton
                variant="outline"
                color="primary"
                block
                onClick={() => {
                  AddJoints.push(
                    PoliLineLocations[PoliLineLocations.length - 1]
                  );
                  MapDataFinall.Joints.push(
                    PoliLineLocations[PoliLineLocations.length - 1]
                  );
                  setMapDataFinall({ ...MapDataFinall });
                  setAddJoints([...AddJoints]);
                }}
              >
                Add Joints
              </CButton>
            </CCol>
          </CRow>
        </Popup>
      </Marker>
    );
  }
  function DeleteDataByID(id) {
    MapApi.DeleteMasterDataById(id, MapKey)
      .then((rsponse) => {
        // console.log(rsponse.data);
        getMasteData();
        getPoliLineMasterData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getDistance(PoliId) {
    var PolyData = [];
    MapApi.getMasterDataById(PoliId, MapKey)
    .then((response) => {
      console.log(response.data);
      if (response.data.data.latlang !== []) {
        console.log(response.data.data.latlang);
        var len = response.data.data.latlang.length,
          newData = { aaData: [] },
          i;
        console.log(len);
        for (i = 0; i < len; i += 1) {
          newData.aaData.push([
            response.data.data.latlang[i].lat,
            response.data.data.latlang[i].lang,
            {
              id: response.data.data.latlang[i].id,

            },
          ]);
        }
        console.log(newData.aaData);
        if (mapRef.current !== null) {
          var polyline = null;
          polyline = L.polyline(newData.aaData, { color: "#00FFFFFF", weight: 0 }).addTo(mapRef.current);
          var previousPoint;
          var finalMeters = 0;
          console.log(polyline);

          //leafletjs.com/reference.html#polyline-getlatlngs
          polyline.getLatLngs().forEach(function (latLng) {
            if (previousPoint) {
              finalMeters += previousPoint.distanceTo(latLng);
              // L.marker(latLng)
              //   .bindPopup(
              //     "Distance from previous point: " +
              //       previousPoint.distanceTo(latLng).toFixed(2) + // http://leafletjs.com/reference.html#latlng-distanceto
              //   `    " meter(s)"
              //   )
              //   .addTo(map);
            }
            previousPoint = latLng;
          });
          var MeterDetails = {
            meters: finalMeters,
          };
          // polyline = null;
          MapApi.UpdateMasterDataById(PoliId, MeterDetails, MapKey)
            .then((response) => {
              getMasteData();
              getPoliLineMasterData();
              updatePolyLine(PoliId);
              // L
              // map.removeLayer(polyline);
              // polyline = null
            })
            .catch((error) => {
              console.log(error);
            });
          console.log(finalMeters);
        }
      }
      // Object.values(response.data.data.latlang).map((datata) => {
      //   console.log(datata);

      //   // PolyData.push(datata);
      // });
    })
    .catch((error) => { });
  }

  //Coupler
  function PostCouplerData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;

        var data = {
          latlang: {
            lat: lat,
            lang: lng,
          },
          master_id: mapDataFinal.id,
          loss_id: 1,
          name: "Coupler",
          icon_type: "coupler",
          icon_name: "this",
          type: "coupler",
        };

        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();
            setEnableCoupler(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }

  //PLC Splitter
  function PostPLCSData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          latlang: {
            lat: lat,
            lang: lng,
          },
          master_id: mapDataFinal.id,
          loss_id: 1,
          name: "plc splitter",
          icon_type: "plc splitter",
          icon_name: "this",
          type: "plc_splitter",
        };

        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();
            setcheckPLCS(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }

  //Joints
  function PostJointData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          type: "joint",
          latlang: {
            lat: lat,
            lang: lng,
          },
          // point_id: PoliId,
          master_id: mapDataFinal.id,
          // polyline_id: PolyIdForJoint,
          loss_id: 1,
          meters: CLossSpliceJoint[0].high_value,
          name: "New Joint",
          icon_type: "Joint",
          icon_name: "this",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();
            setCheckJoint(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }

  //ONU
  function PostONUData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          type: "onu",
          latlang: {
            lat: lat,
            lang: lng,
          },
          data: {},
          // point_id: PoliId,
          master_id: mapDataFinal.id,
          // polyline_id: PolyIdForJoint,
          // loss_id: 1,
          meters: 0,
          name: "New Joint",
          icon_type: "Joint",
          icon_name: "this",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();
            setEnableONU(false);
          })
          .catch((error) => {
            console.log(error);
            swal({
              title: "Warning!",
              text: error.response.data.error,
              icon: "warning",
              button: "Okay!",
            });
          });
      },
    });

    return null;
  }

  //loops
  function PostLoopData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          latlang: {
            lat: lat,
            lang: lng,
          },
          // point_id: PoliId,

          type: "loop",
          meters: "0",
          master_id: mapDataFinal.id,
          loss_id: 1,
          name: "loop",
          icon_type: "loop",
          icon_name: "this",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();

            setCheckLoop(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }

  //ORN
  function PostORNData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          latlang: {
            lat: lat,
            lang: lng,
          },
          // point_id: PoliId,

          type: "orn",
          meters: "1",
          master_id: mapDataFinal.id,
          // loss_id: 1,
          name: "orn",
          icon_type: "orn",
          icon_name: "orn",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();

            setCheckORN(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }

  //Optical Receiver
  function PostORData({ type }) {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          // customer: {
          //   name: "w",
          //   phone: 898565232,
          //   address: "ewqewq",
          //   setupbox_company: "ewqw",
          // },
          type: type,
          // point_id: PoliId,
          latlang: {
            // polyline_id: data.id,
            lat: lat,
            lang: lng,
          },
          master_id: mapDataFinal.id,
          loss_id: 1,
          name: "OR",
          meters: 0,
          icon_type: "or",
          icon_name: "this",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            console.log(respose.data);
            getMasteData();
            setCheckOR(false);
            setCheckORP(false);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }
  const [SearchEnable, setSearchEnable] = useState(false);

  //FTTx
  function PostFTTxData() {
    const map = useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        var data = {
          latlang: {
            lat: lat,
            lang: lng,
          },
          // point_id: PoliId,

          type: "FTTx",
          meters: "0",
          master_id: mapDataFinal.id,
          loss_id: 1,
          name: "FTTx",
          icon_type: "FTTx",
          icon_name: "this",
        };
        MapApi.addMaster(data, MapKey)
          .then((respose) => {
            getMasteData();
            setCheckFTTx(false);
            console.log(respose.data);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

    return null;
  }

  //Customer

  function getCustomerData(port_id) {
    var data = {
      port_id: port_id,
    };
    MapApi.getCustomersData(port_id, data)
      .then((respose) => {
        setCustomersListPoints(respose.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function CustomesPointShow({ data }) {
    const [InputImage, setInputImage] = useState(null);

    return (
      <>
        <Marker
          position={[parseFloat(data.latitude), parseFloat(data.longitude)]}
          icon={L.icon({
            iconUrl: customerIcon,
            iconRetinaUrl: customerIcon,
            iconAnchor: [5, 55],
            popupAnchor: [10, -44],
            iconSize: [60, 90],
          })}
        >
          <Tooltip sticky>
            <center>{data.customer.user_name}</center>
            {/* <img src={data.images} width="200" height={"200"} /> */}
          </Tooltip>
          <Popup minWidth={300} closeButton={false}>
            <table class="table table-bordered table-sm">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{data.customer.user_name}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{data.customer.user_name}</td>
                </tr>
                <tr>
                  <td>area</td>
                  <td>{data.customer.area}</td>
                </tr>
                <tr>
                  <td>city</td>
                  <td>{data.customer.city}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{data.customer.address}</td>
                </tr>

                <tr>
                  <td></td>
                  <td>
                    {" "}
                    <CButton
                      onClick={() => DeleteCustomesByID(data.id)}
                      color="info"
                      block
                      variant="outline"
                    >
                      <Delete color="#ff0000" style={{ color: "red" }} /> Delete
                      Customer
                    </CButton>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </Popup>
        </Marker>
      </>
    );
  }
  function DeleteCustomesByID(id) {
    MapApi.deleteCustomersDataById(id)
      .then((rsponse) => {
        // console.log(rsponse.data);
        getCustomerData(match.params.portnumber);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const getMasteDataForSearch = () => {
    MapApi.getMapAllDataForSearch(match.params.portnumber, MapKey)
      .then((response) => {
        setSearchDataMaster(response.data.data);
        // setFilteredData(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSearch = (e) => {
    console.log(e.target.value=="");
    if(e.target.value=="")
    {
      const term = e.target.value.toLowerCase();
      setSearchTerm(term);

    setFilteredData([]);
    }else{  
        const term = e.target.value.toLowerCase();

    // if(term==="")
    // {
    //   setFilteredData([]);
    // }
    setSearchTerm(term);

    const filtered = SearchDataMaster.filter(item =>
      item.name.toLowerCase().includes(term) || item.type.toLowerCase().includes(term)
    );

    console.log(filtered)
    setFilteredData(filtered);
  }

    
  };
  const groupedData = filteredData.reduce((acc, item) => {
    const key = item.type;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
  //fab icon
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [CLossSpliceJoint, setCLossSpliceJoint] = useState([]);

  function getJointLossSplices(catid) {
    LossAPI.getLossData(catid)
      .then(function (response) {
        setCLossSpliceJoint(response.data.loss);

        console.log(CLossSpliceJoint);
      })
      .catch(function (err) {
        console.log("err");
      });
  }

  const [handleLossModal, sethandleLossModal] = useState(false);
  const [handleLossModalForPLCS, sethandleLossModalForPLCS] = useState(false);
  const [handleImageModal, sethandleImageModal] = useState(false);
  const [handleONUListModal, sethandleONUListModal] = useState(false);
  const [imgURL, setimgURL] = useState("");
  const handleImageView = (img) => {
    setimgURL(img);
    sethandleImageModal(!handleImageModal);
  };

  
  return (
    <div class="containermap">
      <TheHeaderPlanScreen
        mapFunctionallity={true}
        checkPolyLines={checkPolyLines}
        setcheckPolyLines={setcheckPolyLines}
        couplorfun={() => {
          setEnableCoupler(true);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORN(false);
          setcheckPLCS(false);
          setCheckORP(false);
          setEnableONU(false);
        }}
        jointfun={() => {
          setEnableCoupler(false);
          setcheckPolyLines(false);
          setCheckJoint(true);
          setCheckLoop(false);
          setCheckOR(false);
          setcheckPLCS(false);
          setCheckORN(false);
          setEnableONU(false);
          setCheckORP(false);
        }}
        loopfun={() => {
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPLCS(false);
          setCheckLoop(true);
          setcheckPolyLines(false);
          setCheckOR(false);
          setCheckORN(false);
          setCheckORP(false);
          setEnableONU(false);
        }}
        orfun={() => {
          setEnableCoupler(false);
          setcheckPolyLines(false);
          setcheckPLCS(false);
          setCheckJoint(false);
          setCheckLoop(false);
          setCheckOR(true);
          setCheckORN(false);
          setCheckORP(false);
          setEnableONU(false);
        }}
        orpfun={() => {
          setEnableCoupler(false);
          setcheckPolyLines(false);
          setCheckJoint(false);
          setcheckPLCS(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORP(true);
          setCheckORN(false);
          setEnableONU(false);
        }}
        plcfun={() => {
          setcheckPLCS(true);
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORP(false);
          setCheckORN(false);
          setEnableONU(false);
        }}
        onufun={() => {
          setcheckPLCS(false);
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORP(false);
          setCheckORN(false);
          setEnableONU(true);
        }}
        ornfun={() => {
          setcheckPLCS(false);
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORP(false);
          setEnableONU(false);
          setCheckORN(true);
        }}
        cs={true}
        joints={true}
        ls={true}
        ors={true}
        onu={WDMStatus}
        orn={false}
        orps={true}
        title={"GE-Pon Edfa"}
        ps={true}
        navigtionroutesname={"gepon"}
        mapdevicename={MapKey}
        onclickSerach={() => {
          setSearchEnable(!SearchEnable);
        }}
        DeviceID={
          location.state !== undefined ? location.state.category_id : ""
        }
        portNumber={match.params.port}
        portID={match.params.portnumber}
        RefreshCustomerListFun={() => getCustomerData(match.params.portnumber)}
      />
      <MapsidePanel
        polilineUnbleStatus={true}
        checkPolyLines={checkPolyLines}
        setcheckPolyLines={setcheckPolyLines}
        couplorfun={() => {
          setselectedDeviceForhighlight("Coupler");
          setEnableCoupler(true);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORN(false);
          setcheckPLCS(false);
          setCheckORP(false);
          setEnableONU(false);
        }}
        jointfun={() => {
          setselectedDeviceForhighlight("Joint");
          setEnableCoupler(false);
          setcheckPolyLines(false);
          setCheckJoint(true);
          setCheckLoop(false);
          setCheckOR(false);
          setcheckPLCS(false);
          setCheckORN(false);
          setEnableONU(false);
          setCheckORP(false);
        }}
        loopfun={() => {
          setselectedDeviceForhighlight("Loop");
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPLCS(false);
          setCheckLoop(true);
          setcheckPolyLines(false);
          setCheckOR(false);
          setCheckORN(false);
          setCheckORP(false);
          setEnableONU(false);
        }}
        orfun={() => {
          setselectedDeviceForhighlight("Optical Receiver Active");
          setEnableCoupler(false);
          setcheckPolyLines(false);
          setcheckPLCS(false);
          setCheckJoint(false);
          setCheckLoop(false);
          setCheckOR(true);
          setCheckORN(false);
          setCheckORP(false);
          setEnableONU(false);
        }}
        orpfun={() => {
          setselectedDeviceForhighlight("Optical Receiver Passive");
          setEnableCoupler(false);
          setcheckPolyLines(false);
          setCheckJoint(false);
          setcheckPLCS(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORP(true);
          setCheckORN(false);
          setEnableONU(false);
        }}
        plcfun={() => {
          setselectedDeviceForhighlight("PLC Splitter");

          setcheckPLCS(true);
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORP(false);
          setCheckORN(false);
          setEnableONU(false);
        }}
        onufun={() => {
          setselectedDeviceForhighlight("Onu");

          setcheckPLCS(false);
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORP(false);
          setCheckORN(false);
          setEnableONU(true);
        }}
        ornfun={() => {
          setselectedDeviceForhighlight("Optical Reciver Node");

          setcheckPLCS(false);
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setCheckORP(false);
          setEnableONU(false);
          setCheckORN(true);
        }}
        fttxfun={() => {
          setselectedDeviceForhighlight("FTTx");
          setCheckFTTx(true);
          setcheckPLCS(false);
          setEnableCoupler(false);
          setCheckJoint(false);
          setcheckPolyLines(false);
          setCheckLoop(false);
          setCheckOR(false);
          setEnableONU(false);
          setCheckORP(false);
        }}
        cs={true}
        fttx={true}
        joints={true}
        ls={true}
        ors={true}
        onu={WDMStatus}
        orn={true}
        orps={true}
        ps={true}
        selectedDevice={selectedDeviceForhighlight}
      />

      <CModal
        show={EnableMarkerModal}
        onClose={() => setEnableMarkerModal(!EnableMarkerModal)}
        // className="modal-centered"
        // centered
        size="sm"
        // closeOnBackdrop={false}
        fade
      >
        <CModalHeader closeButton>Unable Points</CModalHeader>
        <CCardBody style={{ backgroundColor: "#6ad4f7" }}>
          <center>
            <CTooltip content={"Unable Points"} placement="top">
              <CLabel class="c-switch c-switch-primary">
                <CInput
                  type="checkbox"
                  class="c-switch-input"
                  onChange={(v) => {
                    //console.log(value.portEnable);
                    if (checkPolyLines === true) {
                      setcheckPolyLines(false);
                    } else {
                      setcheckPolyLines(true);
                    }
                  }}
                  checked={checkPolyLines}
                />
                <span class="c-switch-slider"></span>
              </CLabel>
            </CTooltip>
          </center>
        </CCardBody>
      </CModal>
      {/* <SpeedDial
        ariaLabel="Add"
        style={{ margin: 0, right: 20, bottom: 20, position: "fixed" }}
        hidden={false}
        icon={<SpeedDialIcon openIcon={<AddLocation />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            style={{ backgroundColor: "#000000" }}
            color={"#000000"}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
            href={action.link}
          />
        ))}
      </SpeedDial> */}
      {mapDataFinal !== null ? (
        <CRow style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }}>
          <Backdrop open={open} />

          <MapContainer
            // ref={setMap}
            ref={mapRef}
            // whenCreated={setMap}
            center={[
              parseFloat(mapDataFinal.latlang.lat),
              parseFloat(mapDataFinal.latlang.lang),
            ]}
            dragging={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            attributionControl={true}
            //   zoomControl={false}
            // onPopupClose={handlePopupClose}
            zoom={13}
            // onClick={(e) => handleClick(e)}
          >
            {/* <LayersControl>
              <div>asas</div>
            </LayersControl> */}
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
            />

            <AddMainMarker />
            {EnableCoupler === true ? <PostCouplerData /> : null}
            {checkPolyLines === true ? <PostPolilineContinue /> : null}
            {CheckJoint === true ? <PostJointData /> : null}
            {CheckLoop === true ? <PostLoopData /> : null}
            {CheckORN === true ? <PostORNData /> : null}
            {CheckFTTx === true ? <PostFTTxData /> : null}

            {CheckOR === true ? <PostORData type={"optical_reciver"} /> : null}
            {CheckORP === true ? (
              <PostORData type={"optical_reciver_passive"} />
            ) : null}

            {checkPLCS === true ? <PostPLCSData /> : null}
            {EnableONU === true ? <PostONUData /> : null}

            {mapDataFinal.coupler.map((data, i) => (
              <CustomeCouplerShow
                data={data}
                index={i}
                mapRef={mapRef}
                DeleteDataByID={() => DeleteDataByID(data.id)}
                MapKey={MapKey}
                getMasteData={() => getMasteData()}
                handleImageView={() => handleImageView(data.images)}
                map={mapRef.current}
                mapDataFinal={mapDataFinal}
                sethandleLossModal={() => sethandleLossModal(!handleLossModal)}
                setCouplorIDForSaveLossData={() =>
                  setCouplorIDForSaveLossData(data.id)
                }
                PostPolilineMax={() =>
                  PostPoliline(
                    data.max.id,
                    parseFloat(data.latlang.lat),
                    parseFloat(data.latlang.lang)
                  )
                }
                PostPolilineMin={() =>
                  PostPoliline(
                    data.min.id,
                    parseFloat(data.latlang.lat),
                    parseFloat(data.latlang.lang)
                  )
                }
                PostPolilineMaxContinue={() => {
                  setPoliId(data.max.polyline_id);
                  setcheckPolyLines(true);
                }}
                PostPolilineMinContinue={() => {
                  setPoliId(data.min.polyline_id);
                  setcheckPolyLines(true);
                }}
              />
            ))}
            {mapDataFinal.plc_splitter.map((data, i) => (
              <CustomePLCSShow
                data={data}
                index={i}
                MapKey={MapKey}
                PostPoliline={() =>
                  PostPoliline(
                    data.max.id,
                    parseFloat(data.latlang.lat),
                    parseFloat(data.latlang.lang)
                  )
                }
                getMasteData={() => getMasteData()}
                handleImageView={() => handleImageView(data.images)}
                mapDataFinal={mapDataFinal}
                sethandleLossModalForPLCS={() =>
                  sethandleLossModalForPLCS(!handleLossModalForPLCS)
                }
                setPLCSIDForSaveLossData={() =>
                  setPLCSIDForSaveLossData(data.id)
                }
                DeleteDataByID={() => DeleteDataByID(data.id)}
              />
            ))}
            {mapDataFinal.joint.map((data) => (
              <CustomeJointsShow
                data={data}
                DeleteDataByID={() => DeleteDataByID(data.id)}
                MapKey={MapKey}
                PostPoliline={() =>
                  PostPoliline(
                    data.id,
                    parseFloat(data.latlang.lat),
                    parseFloat(data.latlang.lang)
                  )
                }
                PostPolilineContinue={() => {
                  setPoliId(data.polyline_id);
                  setcheckPolyLines(true);
                }}
                getMasteData={() => getMasteData()}
                handleImageView={() => handleImageView(data.images)}
                mapDataFinal={mapDataFinal}
              />
            ))}
            {mapDataFinal.loop.map((data) => (
              <CustomeLoopsShow
                data={data}
                DeleteDataByID={() => DeleteDataByID(data.id)}
                MapKey={MapKey}
                PostPoliline={() =>
                  PostPoliline(
                    data.id,
                    parseFloat(data.latlang.lat),
                    parseFloat(data.latlang.lang)
                  )
                }
                getMasteData={() => getMasteData()}
                handleImageView={() => handleImageView(data.images)}
                mapDataFinal={mapDataFinal}
                PostPolilineContinue={() => {
                  setPoliId(data.polyline_id);
                  setcheckPolyLines(true);
                }}
              />
            ))}
            {mapDataFinal.FTTx.map((data) => (
              <CustomeFTTxshow
                data={data}
                MapKey={MapKey}
                DeleteDataByID={() => DeleteDataByID(data.id)}
                getMasteData={() => getMasteData()}
                fttxModel={() => {
                  console.log(data);
                  setFTTxOBJ(data);
                  console.log(FTTxOBJ);
                  setEnableFTTXModal(!EnableFTTXModal);
                }}
                handleImageView={() => handleImageView(data.images)}
                match={match}
                mapDataFinal={mapDataFinal}
              />
            ))}
            {CustomersListPoints.map((data) => (
              <CustomesPointShow data={data} />
            ))}
            {mapDataFinal.port.wdm_status === 1 ? (
              <>
                {mapDataFinal.orn.map((data) => (
                  <CustomeORNshow
                    data={data}
                    DeleteDataByID={() => DeleteDataByID(data.id)}
                    MapKey={MapKey}
                    PostPoliline={() =>
                      PostPoliline(
                        data.id,
                        parseFloat(data.latlang.lat),
                        parseFloat(data.latlang.lang)
                      )
                    }
                    PostPolilineContinue={() => {
                      setPoliId(data.polyline_id);
                      setcheckPolyLines(true);
                    }}
                    getMasteData={() => getMasteData()}
                    handleImageView={() => handleImageView(data.images)}
                    mapDataFinal={mapDataFinal}
                  />
                ))}
              </>
            ) : null}

            {mapDataFinal.optical_reciver.map((data) => (
              <CustomeORShow
                data={data}
                type="ora"
                DeleteDataByID={() => DeleteDataByID(data.id)}
                MapKey={MapKey}
                getMasteData={() => getMasteData()}
                handleImageView={() => handleImageView(data.images)}
                mapDataFinal={mapDataFinal}
              />
            ))}

            {mapDataFinal.optical_reciver_passive.map((data) => (
              <CustomeORShow
                data={data}
                type="orp"
                DeleteDataByID={() => DeleteDataByID(data.id)}
                MapKey={MapKey}
                getMasteData={() => getMasteData()}
                handleImageView={() => handleImageView(data.images)}
                mapDataFinal={mapDataFinal}
              />
            ))}
            {mapDataFinal.port.wdm_status === 1 ? (
              <>
                {mapDataFinal.onu.map((data) => (
                  <CustomeONUshow
                    data={data}
                    MapKey={MapKey}
                    DeleteDataByID={() => DeleteDataByID(data.id)}
                    getMasteData={() => getMasteData()}
                    handleImageView={() => handleImageView(data.images)}
                    mapDataFinal={mapDataFinal}
                  />
                ))}
              </>
            ) : null}

            <AddFiberLine />
            <AddDataFiberLine />
            {MapFiberLineData !== null ? (
              <>
                {MapFiberLineData.polylines.map((data, i) => {
                  var len = data.latlang.length,
                    newData = { aaData: [] },
                    i;

                  for (i = 0; i < len; i += 1) {
                    newData.aaData.push([
                      data.latlang[i].lat,
                      data.latlang[i].lang,
                      {
                        id: data.latlang[i].id,
                        color: data.color,
                      },
                    ]);
                  }

                  var LatLngData = [];
                  data.latlang.map((data) => {
                    LatLngData.push(data);
                  });
                  var newColor = data.color;

                  return (
                    <Polyline
                      positions={newData.aaData}
                      pathOptions={{ color: newColor }}
                      weight={data.polylinedata.line_width}
                    >
                      <ShowFiberLine
                        DeleteDataByID={() => {
                          DeleteDataByID(data.id);
                        }}
                        data={data}
                        MapKey={MapKey}
                        CLossSpliceJoint={CLossSpliceJoint}
                        map={mapRef.current}
                        setPoliId={() => setPoliId(data.id)}
                        setcheckPolyLinesContinue={() =>
                          setcheckPolyLines(true)
                        }
                        checkPolyLines={checkPolyLines}
                        mapDataFinal={mapDataFinal}
                        setcheckPolyLines={() => setcheckPolyLines(false)}
                        getMasteData={() => {
                          getMasteData();
                          getPoliLineMasterData();
                        }}
                        fiberwidth={data.polylinedata.line_width}
                      />
                    </Polyline>
                  );
                })}
              </>
            ) : null}

            {addFiberLineCheck === true ? <AddPoliMarkers /> : null}
          </MapContainer>
        </CRow>
      ) : (
        <div style={{ backgroundColor: "#ffffff" }}>
          <center>
            <img src={maploadingg} />
          </center>
        </div>
      )}

      <Customers
        id={CustomerID}
        ShowModal={ShowModalForCustomer}
        close={() => setShowModalForCustomer(!ShowModalForCustomer)}
        MapKey={MapKey}
      />
      <LossChart
        CouplorIDForSaveLossData={CouplorIDForSaveLossData}
        show={handleLossModal}
        onClose={() => sethandleLossModal(!handleLossModal)}
        onupdateData={() => getMasteData()}
        MapKey={MapKey}
      />
      <PLCLossChart
        CouplorIDForSaveLossData={PLCSIDForSaveLossData}
        show={handleLossModalForPLCS}
        onClose={() => sethandleLossModalForPLCS(!handleLossModalForPLCS)}
        onupdateData={() => getMasteData()}
        MapKey={MapKey}
      />
      <ImageModal
        show={handleImageModal}
        img={imgURL}
        onClose={() => sethandleImageModal(!handleImageModal)}
      />
      <OnuList
        show={handleONUListModal}
        onClose={() => sethandleONUListModal(!handleONUListModal)}
        data={mapDataFinal !== null ? mapDataFinal.onu : null}
      />
      {/* <Mapdiractionloading
        ShowModal={LoadingModalShowForDirectionOpenMap}
        close={() =>
          setLoadingModalShowForDirectionOpenMap(
            !LoadingModalShowForDirectionOpenMap
          )
        }
      /> */}
      <CModal
        style={{
          width: "100%",
          height: "100%"
        }}
        show={SearchEnable}
        onClose={() => setSearchEnable(!SearchEnable)}
        size="xl"
        fade
      >
        <CCard>
          <CCardHeader closeButton>
            <CRow>
              <CCol>
              Search Devices

              </CCol>
              <CCol style={{float:"right",cursor:"pointer"}}>
                <div style={{float:"right"}}>
                <Close onClick={()=>setSearchEnable(!SearchEnable)}/>

                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol></CCol>
              <CCol>
              <CInput
              placeholder="Search the Devices"
              type="text"
              value={searchTerm}
              onChange={handleSearch}
            />
              </CCol>
              <CCol></CCol>
            </CRow>
           
            <br></br>
            {/* All Devices : */}
            <ul>
              {Object.keys(groupedData).map(category => (
                <CCol key={category.toUpperCase} >
                  <CLabel style={{ borderRadius: 2, color: "#737070", fontWeight: "bolder", paddingBottom: 5, paddingtop: 5, }}>
                    {`${category.toUpperCase()}`}
                  </CLabel>
                  <br />
                  <CCol>
                    {groupedData[category].map(item => (
                     <> <CLabel key={item.type}
                    //  className={"onhover"}
                        onClick={() => {
                          setSearchEnable(!SearchEnable);
                          mapRef.current.flyTo({ lat: item.latlang.lat, lng: item.latlang.lang }, mapRef.current.getZoom())
                        }}
                        style={{
                          width:"100%",
                          marginLeft: 5,
                          paddingLeft: 15,
                          paddingRight: 5,
                          paddingTop: 10,
                          paddingBottom: 10,
                          cursor: "pointer",
                          marginRight: 5,
                          fontWeight: "bolder",
                          borderRadius: 5,
                          backgroundColor:"#dbd9d9",
                          
                          // color: item.type === "coupler" ? "white" : item.type === "loop" ? "black" : item.type === "optical_reciver" ? "white" : item.type === "plc_splitter" ? "black" : item.type === "joint" ? "white" : "white",
                          // backgroundColor:
                          //   item.type === "coupler" ? "blue" : item.type === "loop" ? "yellow" : item.type === "optical_reciver" ? "green" : item.type === "plc_splitter" ? "pink" : item.type === "joint" ? "black" : "blue"
                        }}>
                          {item.name.toUpperCase()}
                          </CLabel><br></br></>
                    ))}
                  </CCol>
                </CCol>
              ))}
            </ul>
            {/* {SearchDataMaster !== null ? <>
              {SearchDataMaster.map((data) => {

                var Groupname = "";
                if (data.)
                  Groupname = data

                return (


                  <CLabel>{data.name}</CLabel>
                )
              })}
            </> : <></>} */}
          </CCardBody>
        </CCard>
      </CModal>
    </div>
  );
}
export default MapforGEPon;
//MapforGEPon
